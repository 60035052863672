'use strict';

import $ from 'jquery';
import SiteNavDss from '../../molecules/site-nav-dss/site-nav-dss';
import SiteSearchDss from '../../molecules/site-search-dss/site-search-dss';
import NotificationDss from '../../molecules/notification-dss/notification-dss';

export default class SiteHeaderDss {
	constructor() {
		let $siteHeader = $('.site-header');
		let $menuToggleBtn = $('.btn-toggle-menu', $siteHeader);
		let $searchToggleBtn = $('.btn-toggle-search', $siteHeader);
		let $mobileSearch = $('.m-site-search', $siteHeader);
		let $siteNav = $('.site-nav', $siteHeader);
		let $notificationButton = $('.btn-toggle-notification', $siteHeader);
		let $notificationBar = $('.notification', $siteHeader);
		let $notificationCount = $('.icomoon-notification__count', $siteHeader);

		this.menuOpened = false;
		this.searchOpened = false;

		this.$siteHeader = $siteHeader;
		this.$siteNav = $siteNav;
		this.$searchToggleBtn = $searchToggleBtn;
		this.$menuToggleBtn = $menuToggleBtn;

		this.$mobileSearch = $mobileSearch;

		this.siteNav = new SiteNavDss();
		this.siteSearch = new SiteSearchDss();
		this.notification = new NotificationDss();

		// flip level 3 to left
		$(window).on('load resize', function(){
			$('.site-nav__level3').map((i, ele) => {
				let left = $(ele).offset().left,
					width = $(ele).width();

				if(left + width > $(window).width()){
					$(ele).parent().addClass('flipped');
				}

				if ($(window).width() > 1919) {
					$(ele).parent().removeClass('flipped');
				}
			});
		});

		$notificationButton.on('click', function (e) {
			e.preventDefault();

			if (!$notificationBar.hasClass('active')) {
				$notificationBar.addClass('active');
				$notificationBar.slideDown(300);

            	//check again
				if ($siteHeader.hasClass('menu-opened')) {
					$siteHeader.removeClass('menu-opened');
					$siteNav.slideUp();
					$menuToggleBtn.removeClass('active');
					$mobileSearch.slideUp();
				}
			} else {
				$notificationBar.removeClass('active');
				$notificationBar.slideUp(300);
			}
		});

    	// Animate the notification bar count
		if($notificationCount.text() == '0'){
			setTimeout(function(){
				$notificationCount.addClass('blinkin');
			}, 150);
		}
		else {
			setTimeout(function(){
				$notificationCount.addClass('popup');
			}, 550);
		}

		$searchToggleBtn.on('click', e => {
			if (this.searchOpened) {
				this.closeSearch();
			} else {
				this.openSearch();
				$notificationBar.slideUp('slow');
				$notificationBar.removeClass('active');
			}
		});

		$menuToggleBtn.on('click', e => {
			if (this.menuOpened) {
				this.closeMenu();
			} else {
				this.openMenu();
				$notificationBar.slideUp('slow');
				$notificationBar.removeClass('active');
			}
		});

		$(document).on('click.headerCollapse', e => {
			let $eTarget = $(e.target);

			if (!($eTarget.hasClass('site-search') || $eTarget.parents('.site-search').length || $eTarget.hasClass('m-site-search') || $eTarget.parents('.m-site-search').length || $eTarget.hasClass('btn-toggle-search') || $eTarget.parents('.btn-toggle-search').length)) {
				this.closeSearch();
			}
		});
	}

	openMenu() {
		this.$siteHeader.addClass('menu-opened');
		this.$siteNav.slideDown();
		this.$menuToggleBtn.addClass('active');
		this.menuOpened = true;
		this.$mobileSearch.slideDown();
	}

	closeMenu() {
		if (this.siteNav.lvl2Expanded) {
			this.siteNav.backLevelOne();
			setTimeout(() => {
				this.$siteHeader.removeClass('menu-opened');
				this.$siteNav.slideUp();
				this.$menuToggleBtn.removeClass('active');
				this.menuOpened = false;
				this.$mobileSearch.slideUp();
			}, 250);
		} else {
			this.$siteHeader.removeClass('menu-opened');
			this.$siteNav.slideUp();
			this.$menuToggleBtn.removeClass('active');
			this.menuOpened = false;
			this.$mobileSearch.slideUp();
		}
	}

	openSearch() {
		this.siteSearch.open();
		this.$siteHeader.addClass('search-opened');
		this.$searchToggleBtn.addClass('active');
		this.$searchToggleBtn.find('.icomoon').removeClass('icomoon-search').addClass('icomoon-close');
		this.$searchToggleBtn.find('.icomoon span').html('Close');
		this.searchOpened = true;
	}

	closeSearch() {
		this.siteSearch.close();
		this.$siteHeader.removeClass('search-opened');
		this.$searchToggleBtn.removeClass('active');
		this.$searchToggleBtn.find('.icomoon').removeClass('icomoon-close').addClass('icomoon-search');
		this.$searchToggleBtn.find('.icomoon span').html('Search');
		this.searchOpened = false;
	}
}
