'use strict';

import $ from 'jquery';
import moment from 'moment';

export default class NewsReleasesWidget {
	constructor() {
		let $newsWidget = $('.news-releases-widget'),
			$dateFilter = $('.date-filter', $newsWidget),
			$startDate = $('#filterStartDate', $dateFilter),
			$endDate = $('#filterEndDate', $dateFilter),
			$applyBtn = $('.date-apply', $dateFilter),
			$pagination = $('.pagination');

		let pageURL = window.location.pathname;

		$applyBtn.on('click', function(e) {
			e.preventDefault();
			let startValue = $startDate.val(),
				endValue = $endDate.val(),
				pageVal;

			if (typeof startValue !== 'undefined' && startValue !== "" && startValue !== "-") {
				startValue = moment(startValue).format('DD-MMM-YYYY');
			}

			if (typeof endValue !== 'undefined' && endValue !== "" && endValue !== "-") {
				endValue = moment(endValue).format('DD-MMM-YYYY');
			}

			$('li', $pagination).each(function(){
				let $this = $(this),
					$link = $("a", $this);

				if ($link.hasClass('active')) {
					pageVal = $link.data("page");
				}
			});

			window.location.href = getQuery(pageURL, startValue, endValue, pageVal);

		});

		function getQuery(pageURL, start, end, page) {
			return pageURL + '?start=' + start + '&end=' + end + '&page=' + page;
		}

	}
}
