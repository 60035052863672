'use strict';

import $ from 'jquery';
import store from 'store2';

/*
* Sitefinity in default using HTML5 form validation
* HTML5 form validation is not working in iOS, hence customize the validation for input and textarea
* HTML5 Input pattern attribute is being used to validate the input field, and minlength/maxlength for textarea in iOS
* NOTE: Sitefinity 9.2 has bug on HTML input pattern attribute (missing quotes)
* Temporarily remove REGEX validation with HTML5 input pattern
*/
export default class FormWrapper {
	constructor() {

		$('.sheltermatters, .safetylicensing, .safetymanager, .nationalservice, .firehazard').addClass('no-display');

		let $formContainer = $('.form-wrapper');

		// custom file upload style
		let $fileUpload = $('.sf-FileField');

		$fileUpload.each(function(){
			let $this = $(this);

			let customHTML = `<button type="button" class="upload-btn">CHOOSE FILE</button><span class="filename">No file chosen</span>`;
			let $field = $('.sf-FileField-input', $this);

			$field.addClass('hide');
			$field.before(customHTML);

			$('button', $this).on('click', () => {
				$field.trigger('click');
			});

			$field.on('change', () => {
				let filename = $('input[type=file]').val().split('\\').pop();
				$('.filename', $this).text(filename);
			});
		});

		$('.sheltermatters select, .safetylicensing select, .safetymanager select, .nationalservice select, .firehazard select').removeAttr('required');
		$('.sheltermatters select, .safetylicensing select, .safetymanager select, .nationalservice select, .firehazard select').attr('disabled', 'disabled');

		//custom select
		$('select').each(function(){
			let $select = $(this),
				$label = $('<span class = "label"/>'),
				$icon = $('<span class = "icomoon-dropdown"/>'),
				$wrapper = $('<div class = "custom-select-wrapper"/>');

			$select.wrap( $wrapper );
			$select.after($label);
			$select.after($icon);

			$label.text($select.find(':selected').text());

			$select.on('change', function(){
				var $option = $(this).find(':selected'),
					text = $option.val();

				var subjectDD = $('.subject').find(':selected'),
					subjectText = subjectDD.val();

				if (text === "Fire Safety & Shelter Matters") {
					$('.sheltermatters').removeClass('no-display');
					$('.safetylicensing, .safetymanager, .nationalservice, .firehazard').addClass('no-display');
					$('.sheltermatters select').attr('required', 'required');
					$('.sheltermatters select').removeAttr('disabled');
					$('.safetylicensing select, .safetymanager select, .nationalservice select, .firehazard select').removeAttr('required');
					$('.safetylicensing select, .safetymanager select, .nationalservice select, .firehazard select').attr('disabled', 'disabled');
				} else if (text === "Fire Safety Licensing, Permit & Certification") {
					$('.safetylicensing').removeClass('no-display');
					$('.sheltermatters, .safetymanager, .nationalservice, .firehazard').addClass('no-display');
					$('.safetylicensing select').attr('required', 'required');
					$('.safetylicensing select').removeAttr('disabled');
					$('.sheltermatters select, .safetymanager select, .nationalservice select, .firehazard select').removeAttr('required');
					$('.sheltermatters select, .safetymanager select, .nationalservice select, .firehazard select').attr('disabled', 'disabled');
				} else if (text === "Fire Safety Manager (FSM)") {
					$('.safetymanager').removeClass('no-display');
					$('.sheltermatters, .safetylicensing, .nationalservice, .firehazard').addClass('no-display');
					$('.safetymanager select').attr('required', 'required');
					$('.safetymanager select').removeAttr('disabled');
					$('.sheltermatters select, .safetylicensing select, .nationalservice select, .firehazard select').removeAttr('required');
					$('.sheltermatters select, .safetylicensing select, .nationalservice select, .firehazard select').attr('disabled', 'disabled');
				} else if (text === "National Service") {
					$('.nationalservice').removeClass('no-display');
					$('.sheltermatters, .safetylicensing, .safetymanager, .firehazard').addClass('no-display');
					$('.nationalservice select').attr('required', 'required');
					$('.nationalservice select').removeAttr('disabled');
					$('.sheltermatters select, .safetylicensing select, .safetymanager select, .firehazard select').removeAttr('required');
					$('.sheltermatters select, .safetylicensing select, .safetymanager select, .firehazard select').attr('disabled', 'disabled');
				} else if (text === "Reporting of Fire Hazard") {
					$('.firehazard').removeClass('no-display');
					$('.sheltermatters, .safetylicensing, .safetymanager, .nationalservice').addClass('no-display');
					$('.firehazard select').attr('required', 'required');
					$('.firehazard select').removeAttr('disabled');
					$('.sheltermatters select, .safetylicensing select, .safetymanager select, .nationalservice select').removeAttr('required');
					$('.sheltermatters select, .safetylicensing select, .safetymanager select, .nationalservice select').attr('disabled', 'disabled');
				} else {
					if (subjectText != "Fire Safety & Shelter Matters" && subjectText != "Fire Safety Licensing, Permit & Certification" && subjectText != "Fire Safety Manager (FSM)" && subjectText != "National Service" && subjectText != "Reporting of Fire Hazard") {
						$('.sheltermatters, .safetylicensing, .safetymanager, .nationalservice, .firehazard').addClass('no-display');
						$('.sheltermatters select, .safetylicensing select, .safetymanager select, .nationalservice select, .firehazard select').removeAttr('required');
					}
				}

				$label.text($option.text());
			});
		});

		$('.form-wrapper input[type="text"]').each((i, ele) => {
			let $this = $(ele),
			value = $this.val();

			if (typeof $this.attr('pattern') === 'undefined') return;

			$this.on('input.validate', (e) => {
				let newValue = $this.val();

				if (!this.validateInput($this)) {
					$this.val(value);
					return false;
				}
				value = newValue;

			});

			$this.on('change.validate', (e) => {
				let newValue = $this.val();

				if (!this.validateInput($this)) {
					$this.val(value);
					return false;
				}
				value = newValue;

			});
		});

		$('input, textarea, select', $formContainer).each(function() {
			let $this = $(this);

			if ($this.prop('required')) {
				$this.parents('.sf-fieldWrp').addClass('is-required');
			}

			$this.on('input', function(e) {
				if (!$this.is(':invalid')) {
					$this.removeClass('is-invalid');
				} else {
					$this.addClass('is-invalid');
				}
			});

			$this.on('change', function(e) {
				let $customWrapper = $this.parents('.custom-select-wrapper');
				if (!$this.is(':invalid')) {
					$customWrapper.removeClass('is-invalid');
				} else {
					$customWrapper.addClass('is-invalid');
				}
			});

			$this.on('invalid', function(e) {
				$this.addClass('is-invalid');
			});
		});

		/*
		* Fallback for iOS: HTML5 validation not working
		* This will also provide the basic validation if any HTML5 validation not working
		*/
		$('.feedback-submit-btn button').on('click', e => {
			e.preventDefault();
			let scrolledToError = false;	// flag used to scroll to first invalid element

			$('.form-wrapper input').not('input[type="submit"]').not('input[type="checkbox"]').not('input[type="radio"]').not('input[type="file"]').not('input[type="hidden"]').map((i, ele) => {
				let $this = $(ele),
				$parent = $this.parents('.sf-fieldWrp'),
				numberRegex = /^\(?([8-9]{1})\)?([0-9]{7})$/;

				if ($parent.hasClass('is-required')) {
					if ($this.val().trim() === "") {
						this.setFieldInvalid($this, !scrolledToError);
						e.preventDefault();
						scrolledToError = true;
					} else if ($this.attr('type') === 'number' && !(numberRegex.test($this.val()))) {
						this.setFieldInvalid($this, !scrolledToError);
						e.preventDefault();
						scrolledToError = true;
					} else if (!this.validateInput($this)) {
						this.setFieldInvalid($this, !scrolledToError);
						e.preventDefault();
						scrolledToError = true;
					} else {
						$this.removeClass('is-invalid');
					}
				} else {
					if ($this.val().trim() !== "" && !this.validateInput($this)) {
						// Field got filled up even though it's not mandatory
						this.setFieldInvalid($this, !scrolledToError);
						e.preventDefault();
						scrolledToError = true;
					} else if ($this.attr('type') === 'number' && !(numberRegex.test($this.val()))) {
						this.setFieldInvalid($this, !scrolledToError);
						e.preventDefault();
						scrolledToError = true;
					}
					else {
						$this.removeClass('is-invalid');
					}
				}
			});

			$('textarea', $formContainer).map((i, ele) => {
				let $this = $(ele),
				$parent = $this.parents('.sf-fieldWrp');

				if ($parent.hasClass('is-required')) {
					if ($this.val().trim() === "") {
						this.setFieldInvalid($this, !scrolledToError);
						e.preventDefault();
						scrolledToError = true;
					} else if (!this.validateTextarea($this)) {
						this.setFieldInvalid($this, !scrolledToError);
						e.preventDefault();
						scrolledToError = true;
					} else {
						$this.removeClass('is-invalid');
					}
				} else {
					if ($this.val().trim() !== "" && !this.validateTextarea($this)) {
						this.setFieldInvalid($this, !scrolledToError)
						e.preventDefault();
						scrolledToError = true;
					} else {
						$this.removeClass('is-invalid');
					}
				}
			});

			/*
			* Validation on Select field
			*/
			$('select', $formContainer).map((i, ele) => {
				let $this = $(ele),
					$customWrapper = $this.parents('.custom-select-wrapper'),
					$parent = $this.parents('.sf-fieldWrp');

				if ($parent.hasClass('is-required')) {
					if ($this.val().trim() === "") {
						this.setFieldInvalid($customWrapper, !scrolledToError);
						e.preventDefault();
						scrolledToError = true;
					} else {
						$customWrapper.removeClass('is-invalid');
					}
				}
			});

			if (scrolledToError) {
				$('html, body').animate({
					scrollTop: $('.form-wrapper').offset().top - 150
				});
			} else {
				store('formPage', true);
			}

			return false;
		});

		if (store('formPage')) {
			let $firstChild = $('.form-wrapper').find('.rte').first();
			$firstChild.addClass('no-display');
			store('formPage', false);
		} else {
			let $firstChild = $('.form-wrapper').find('.rte').first();
			$firstChild.removeClass('no-display');
		}
	}

	validateInput($input) {
		if (typeof $input.attr('pattern') === 'undefined') return true;

		let isValid = true,
		regex;

		switch ($input.attr('type')) {
			case 'tel':
				// regex = /^\+?(\d[\d-.]+)?(\([\d-.]+\))?[\d-.]+\d$/
				regex = /^[0-9]*\-?[0-9]*$/;
				break;
			case 'email':
				regex = /^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,4}$/;	// pattern from Sitefinity 9.2
				break;
			case 'number':
				// regex = /^[0-9]*$/;
				regex = /^\(?([8-9]{1})\)?([0-9]{7})$/;
				break;
			default:
				regex = new RegExp('^' + $input.attr('pattern') + '$');
		}

		isValid = (regex.test($input.val()));

		return isValid;
	}

	validateTextarea($textarea) {
		let minLength;
		let maxLength;
		if (typeof $textarea.attr('minlength') !== 'undefined' && typeof $textarea.attr('maxlength') !== 'undefined') {
			minLength = parseInt($textarea.attr('minlength'));
			maxLength = parseInt($textarea.attr('maxlength'));

			return ($textarea.val().trim().length >= minLength && $textarea.val().trim().length <= maxLength);
		} else if (typeof $textarea.attr('minlength') !== 'undefined') {
			minLength = parseInt($textarea.attr('minlength'));

			return ($textarea.val().trim().length >= minLength);
		} else if (typeof $textarea.attr('maxlength') !== 'undefined') {
			maxLength = parseInt($textarea.attr('maxlength'));

			return ($textarea.val().trim().length <= maxLength);
		} else {
			return true;
		}
	}

	setFieldInvalid($field, scrollToError) {
		$field.addClass('is-invalid');
	}
}
