'use strict';

import $ from 'jquery';

export default class HandbookListing {
	constructor() {
		$('.handbook-listing .dropdown-filter').map((i, ele) => {
			let $this = $(ele);

			$this.on('click', (e) => {
				e.stopPropagation();

				let $option = $this.find('.options'),
					$selected = $this.find('.dropdown-filter__selected');

				if(!$option.hasClass('show')){
					this.showOptions($option, $('strong', $selected));
				}
				else {
					this.hideOptions($option, $('strong', $selected));
				}
			});
		});

		$(window).on('click', () => {
			$('.handbook-listing .dropdown-filter').find('.options').removeClass('show');
			$('.handbook-listing .dropdown-filter').find('.dropdown-filter__selected strong').removeClass('rotate');
		})
	}

	showOptions($list, $icon){
		$list.addClass('show');
		$icon.addClass('rotate');
	}

	hideOptions($list, $icon){
		$list.removeClass('show');
		$icon.removeClass('rotate');
	}
}
