'use strict';

import $ from 'jquery';
import enquire from 'enquire.js';

export default class NewsItem {
	constructor() {
		let $newsItem = $('.news-item'),
			$newsItemWidth = $newsItem.outerWidth(),
			$newsTriangle = $('.news-item__triangle', $newsItem),
			$newsContent = $('.news-item__content', $newsItem),
			$newsDesc = $('.text-description', $newsContent);

		let triangleWidth = 30;

		$newsTriangle.css("border-left", ($newsItemWidth + triangleWidth) + 'px solid transparent');

		$(window).on('resize', () => {
			$newsItemWidth = $newsItem.outerWidth();

			$newsTriangle.css("border-left", ($newsItemWidth + triangleWidth) + 'px solid transparent');
		});

		enquire.register('screen and (max-width: ' + (1024 - 1) + 'px)', {
			match: () => {
				$newsDesc.each(function() {
					if ($(this)[0].scrollHeight > $(this)[0].clientHeight) {
						$(this).addClass('ellipsis short');
					}
				});

				 $('.text-description.ellipsis').each(function () {
					var original = $(this).clone().addClass('original notruncation').removeClass('short').hide();
					$(this).after(original);

					//debugger;
					var shortText = '';
					shortText = $(this).html().trim().substring(0, 60) + '...';
					$(this).html(shortText);
				});
			},
			unmatch: () => {
				$newsDesc.each(function() {
					$(this).removeClass('ellipsis short');
				});
			}
		});

		enquire.register('screen and (min-width: ' + (1024) + 'px)', {
			match: () => {
				$newsItem.each(function() {
					var $this = $(this),
						newsLink = $('a', $this),
						newsOverlay = $('.news-item__image-overlay', $this);

					newsLink.on('mouseover', function() {
						newsOverlay.addClass('is-active');
						newsOverlay.removeClass('not-active');
					});

					newsLink.on('mouseleave', function() {
						newsOverlay.addClass('not-active');
						newsOverlay.removeClass('is-active');
					});
				});
			},
			unmatch: () => {
				$('.news-item__image-overlay').css("height", "0");
			}
		});
	}
}
