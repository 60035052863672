'use strict';

import $ from 'jquery';
import matchHeight from 'jquery-match-height';

export default class FontResizer {
	constructor() {

		let $fontResizer = $('.js-resizer');

		let $landingWrapper = $('.landing-wrapper'),
			$landingItems = $('.landing-wrapper__item', $landingWrapper),
			$landingItemContents = $('.link-card__content', $landingItems);

		let $tabbedInfo = $('.tabbed-info'),
		$tabbedInfoItemTitle = $('.snippet-info__title', $tabbedInfo);

		//change font size
		$fontResizer.on('click', function(e) {
			e.preventDefault();
			let size = $(this).data('size');

			$fontResizer.removeClass('is-active');
			$(this).addClass('is-active');

			if (size == 'normal') {
				$('body').removeClass('font-large').removeClass('font-larger').addClass('font-normal');
			} else if (size == 'big') {
				$('body').removeClass('font-larger').removeClass('font-normal').addClass('font-large');
			} else if (size == 'bigger') {
				$('body').removeClass('font-normal').removeClass('font-large').addClass('font-larger');
			}

			emitter.emit('fontResize');
		});
	}
}
