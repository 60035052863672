'use strict';

import $ from 'jquery';
import enquire from 'enquire.js';

export default class BellNotification {
	constructor() {
		let $bell = $('.header__bell'),
			$bellToggle = $('.bell-notification', $bell);

		let hoverTimeout;

		enquire.register("screen and (min-width:1024px)", {
			match() {
				$(document).on('click', (e) => {
					if (!$(".icomoon-bell").is(e.target)) {
						bellClose();
					}
				});

				$bellToggle.on('click', (e) => {
					e.preventDefault();
					if ($bellToggle.hasClass('is-active')) {
						bellClose();
					} else {
						bellOpen();
					}
				});
			},
			unmatch() {
				bellClose();
			}
		});

		function bellOpen() {
			$bellToggle.addClass('is-active');
		}
		function bellClose() {
			$bellToggle.removeClass('is-active');
		}
	}
}
