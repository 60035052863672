'use strict';

import $ from 'jquery';
import doT from 'doT';
import moment from 'moment';
// import 'socialfeed';
// import 'codebird';

window.doT = doT;
window.moment = moment;

export default class SocialWidget {
	constructor() {
		let $socialWrapper = $('.social-widget'),
			$socialCarousel = $('.social-widget__carousel', $socialWrapper),
			$carouselNav = $('.slider-nav', $socialCarousel),
			$carouselContent = $('.slider-content', $socialCarousel);

		$('.social-widget__media').socialfeed({
			// FACEBOOK
			facebook:{
				accounts: ['@SCDFpage'],  //Array: Specify a list of accounts from which to pull wall posts
				limit: 2,                                   //Integer: max number of posts to load
				access_token: '695655260780635|bkdEBZ3mJRPitziVauARRquKfvE'  //String: "APP_ID|APP_SECRET"
			},
			twitter:{
				accounts: ['@SCDF'],                      //Array: Specify a list of accounts from which to pull tweets
				limit: 2,                                   //Integer: max number of tweets to load
				consumer_key: '2zLWfYjLHgzqoUonWhyyZZfCt',          //String: consumer key. make sure to have your app read-only
				consumer_secret: 'jC8ZeUW6yOfenyc8KQKOk3S1zJJQtAKLLSpEK4Wbj5xT7YC0ph', //String: consumer secret key. make sure to have your app read-only
				tweet_mode: 'extended'                 //String: change to "extended" to show the whole tweet
			},
			instagram:{
				accounts: ['@myscdf'],  //Array: Specify a list of accounts from which to pull posts
				limit: 2,                                   //Integer: max number of posts to load
				// client_id: '7109377951',       //String: Instagram client id (option if using access token)
				// access_token: '7109377951.6129b5f.02bf50fb323a490fbbd04b42b9c1c4f5' //String: Instagram access token
				client_id: 'af9fb91e19704b3ba49b31348fb0fd08',       //String: Instagram client id (option if using access token)
				access_token: '319477244.af9fb91.702c4198186048fc8b09c1c2eea8c684' //String: Instagram access token
			},

			// GENERAL SETTINGS
			show_media:true,                                //Boolean: if false, doesn't display any post images
			template_html:                                  //String: HTML used for each post. This overrides the 'template' filename option
				'<div class="social-widget__item">  \
					<div class="social-widget__images">  \
						<a href={{=it.link}} target="_blank">  \
							<strong class="icomoon-{{=it.social_network}}"></strong>  \
						</a> \
						{{=it.attachment}}  \
					</div>  \
				</div>'
		});

		$('.social-widget__content').socialfeed({
			// FACEBOOK
			facebook:{
				accounts: ['@SCDFpage'],  //Array: Specify a list of accounts from which to pull wall posts
				limit: 2,                                   //Integer: max number of posts to load
				access_token: '695655260780635|bkdEBZ3mJRPitziVauARRquKfvE'  //String: "APP_ID|APP_SECRET"
			},
			twitter:{
				accounts: ['@SCDF'],                      //Array: Specify a list of accounts from which to pull tweets
				limit: 2,                                   //Integer: max number of tweets to load
				consumer_key: '2zLWfYjLHgzqoUonWhyyZZfCt',          //String: consumer key. make sure to have your app read-only
				consumer_secret: 'jC8ZeUW6yOfenyc8KQKOk3S1zJJQtAKLLSpEK4Wbj5xT7YC0ph', //String: consumer secret key. make sure to have your app read-only
				tweet_mode: 'extended'                 //String: change to "extended" to show the whole tweet
			},
			instagram:{
				accounts: ['@myscdf'],  //Array: Specify a list of accounts from which to pull posts
				limit: 2,                                   //Integer: max number of posts to load
				client_id: 'af9fb91e19704b3ba49b31348fb0fd08',       //String: Instagram client id (option if using access token)
				access_token: '319477244.af9fb91.702c4198186048fc8b09c1c2eea8c684' //String: Instagram access token
			},

			// GENERAL SETTINGS
			length:400,                                     //Integer: For posts with text longer than this length, show an ellipsis.
			show_media:false,                                //Boolean: if false, doesn't display any post images
			template_html:                                  //String: HTML used for each post. This overrides the 'template' filename option
				'<div class="social-widget__block">  \
					<p class="account"> \
						<img class="account-picture" src={{=it.author_picture}} /> \
						<span>{{=it.author_name}}</span> \
					</p>  \
					<p>{{=it.text}}</p>  \
				</div>'
		});
	}
}
