'use strict';

import $ from 'jquery';
import Pikaday from 'pikaday';

export default class NewsListing {
	constructor() {
		let $newsListing = $('.news-listing'),
			$dropdown = $('.dropdown-filter', $newsListing),
			$select= $('.dropdown-filter__selected', $newsListing),
			$options = $('.options', $newsListing),
			$dateFilter = $('.filterby-date', $newsListing),
			$dateInput = $('.filterby-date__input', $dateFilter),
			$filterController = $('.btn-filter-controller'),
			$filterBar = $('.news-listing__filterby', $newsListing),
			$newsSelect = $('.news-listing__sortby-selected'),
			$newsOptions = $('.options', $newsSelect),
			$pagination = $('.pagination'),
			pageSizeItems = [];

		let pageURL = window.location.pathname;
		let pageLocation = $filterBar.data('location');

		//filter accordion on mobile
		$filterController.on('click', function(e){
			e.preventDefault();
			let $this = $(this);

			if(!$this.hasClass('open')) {
				$this.parents('.news-listing__filterby--header').siblings('.news-listing__filterby--list').slideDown(300);
				$this.addClass('open');
				$this.find('.icomoon-plus').removeClass('icomoon-plus').addClass('icomoon-minus');

			}else {
				$this.parents('.news-listing__filterby--header').siblings('.news-listing__filterby--list').slideUp(300);
				$this.removeClass('open');
				$this.find('.icomoon-minus').removeClass('icomoon-minus').addClass('icomoon-plus');
			}
		});

		$newsOptions.find('li').each(function() {
			pageSizeItems.push($(this).find('a').text());
		});

		// let pageSizeParam = this.qs('sort');
		// for (let i = 0; i < pageSizeItems.length; i++) {
		// 	if (pageSizeItems[i].toLowerCase() === pageSizeParam) {
		// 		$('span', $newsSelect).text(pageSizeItems[i]);
		// 	}
		// }

		$dropdown.map((i, ele) => {
			let $this = $(ele);
			$this.on('click', (e) => {
				e.stopPropagation();

				let $option = $this.find('.options'),
					$selected = $this.find('.dropdown-filter__selected');

				if(!$option.hasClass('show')){
					this.showOptions($option, $('strong', $selected));
					// console.log('show');
				}
				else {
					this.hideOptions($option, $('strong', $selected));
					// console.log('hide');
				}
			});
		});


		// options clicks handler
		$('li a', $options).map((i,ele) => {
			let $this = $(ele);

			$this.on('click', (e) => {
				e.preventDefault();
				e.stopPropagation();

				let $selected = $this.parents($options).siblings('.dropdown-filter__selected');

				if ($this.parents($options).hasClass('sortby-options')) {
					$('span > span', $selected).text($this.text());


					this.hideOptions($options, $('strong', $selected));

					let redirectUrl = $this.attr('href');
					if(redirectUrl !== '' && redirectUrl) {
						window.location.href = redirectUrl;
					}
				} else {
					$('span', $selected).text($this.text());
					$selected.data('value', $this.data('value'));
					this.hideOptions($options, $('strong', $selected));

					// console.log($selected.data('value', $this.data('value')));
				}

			});
		});

		//datepicker
		if ($('.news-listing__filterby--list').length) {
			this.startPicker = new Pikaday({
				field: document.getElementById('filterbyStartDate'),
				format: 'DD MMM YYYY',
				onSelect: () => {
					this.endPicker.setMinDate(new Date(this.startPicker.getDate().getTime()+(24*60*60*1000)));
					// this.endPicker.setMaxDate(new Date(this.startPicker.getDate().getTime()+(365*24*60*60*1000)));
					// this.endPicker.setMaxDate(new Date(this.startPicker.getDate().getTime()));
					// console.log(new Date(this.startPicker.getDate().getTime()+(24*60*60*1000)));
				}
			});

			this.endPicker = new Pikaday({
				field: document.getElementById('filterbyEndDate'),
				format: 'DD MMM YYYY',
				onSelect: () => {
					// this.startPicker.setMinDate(new Date(this.endPicker.getDate().getTime()-(365*24*60*60*1000)));
					// this.startPicker.setMinDate(new Date(this.endPicker.getDate().getTime()));
					this.startPicker.setMaxDate(new Date(this.startPicker.getDate().getTime()-(24*60*60*1000)));
				}
			});
		}


		let $applyBtn = $('.btn-apply', $newsListing),
			$startDate = $('#filterbyStartDate', $dateFilter),
			$endDate = $('#filterbyEndDate', $dateFilter),
			$dropdownNews = $('.filterby__news .dropdown-filter__selected', $filterBar),
			$dropdownTag = $('.filterby__tag .dropdown-filter__selected', $filterBar),
			$clearBtn = $('.btn-clear', $newsListing);

		$clearBtn.on('click', function(e) {
			e.preventDefault();
			window.location.href = pageLocation;
		});

		$applyBtn.on('click', function(e) {
			e.preventDefault();
			let startValue = $startDate.val(),
				endValue = $endDate.val(),
				pageVal,
				newsVal = $dropdownNews.data('value'),
				tagVal = $dropdownTag.data('value');

			if (typeof startValue !== 'undefined' && startValue !== "" && startValue !== "-") {
				startValue = moment(startValue).format('DD-MMM-YYYY');
			} else {
				startValue = "01-Jan-0001";
			}

			if (typeof endValue !== 'undefined' && endValue !== "" && endValue !== "-") {
				endValue = moment(endValue).format('DD-MMM-YYYY');
			} else {
				endValue = "31-Dec-9999";
			}

			$('li', $pagination).each(function(){
				let $this = $(this),
					$link = $("a", $this);

				if ($link.hasClass('active')) {
					pageVal = $link.data("page");
				}
			});

			// console.log(newsVal, tagVal);

			window.location.href = getQuery(pageLocation, startValue, endValue, newsVal, tagVal);

		});

		function getQuery(pageURL, start, end, newsDropdown, tagDropdown) {
			return pageURL + '/' + start + '/' + end + '/' + newsDropdown + '/' + tagDropdown + '/latest';
		}
	}

	showOptions($list, $icon){
		$list.addClass('show');
		$icon.addClass('rotate');
	}

	hideOptions($list, $icon){
		$list.removeClass('show');
		$icon.removeClass('rotate');
	}

	// qs(queryString) {
	// 	let _self = this,
	// 		query = window.location.search.substring(1),
	// 		parms = query.split('&');

	// 	for (var i=0; i<parms.length; i++) {
	// 		var pos = parms[i].indexOf('=');
	// 		if (pos > 0  && queryString == parms[i].substring(0,pos)) {
	// 			return parms[i].substring(pos+1);
	// 		}
	// 	}
	// 	return "";
	// }
}
