'use strict';

import $ from 'jquery';

export default class FirecodeAccordion {
	constructor() {
		let $firecodeAccordion = $('.firecode-accordion');

		$firecodeAccordion.map((i, ele) => {
			let $this = $(ele),
				$firecodeAccordionController = $('.firecode-accordion__controller', $this),
				$firecodeAccordionContent = $('.firecode-accordion__content', $this);

			$firecodeAccordionController.on('click', () => {
				$this.addClass('clicked');

				$('.firecode-accordion').each(function() {
					if(!$(this).hasClass('clicked')){
						$(this).find('.firecode-accordion__content').slideUp(300);
						$(this).removeClass('open');
					} else {
						$(this).removeClass('clicked');
					}
				});

				if(!$this.hasClass('open')){
					this.open($this);
					emitter.emit('openAccordion');
				}
				else {
					this.close($this);
				}
			});
		});
	}

	close($object) {
		$object.find('.firecode-accordion__content').slideUp(300);
		$object.removeClass('open');
	}

	open($object) {
		$object.find('.firecode-accordion__content').slideDown(300);
		$object.addClass('open');
	}
}
