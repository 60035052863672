'use strict';

import $ from 'jquery';

export default class StickyNavDss {
	constructor($sticky) {
		this.stickynavDefault = $('button.sticky-nav__default', $sticky);
		this.stickynavCollapsed = $('button.sticky-nav__collapsed', $sticky);
		this.stickynavList = $('.sticky-nav__list', $sticky);

		$(window).on('load scroll resize', () => {
			let rightOffsetDefault = (this.stickynavDefault.innerWidth() / 2) - (this.stickynavDefault.innerHeight() / 2);

			this.stickynavDefault.css('right', `-${rightOffsetDefault}px`);


			if (this.stickynavList.hasClass('active')) {
				this.stickynavList.removeClass('active');
			}

			if($(window).scrollTop() > 200){
				if(!$sticky.hasClass('collapsed')){
					$sticky.addClass('collapsed');
				}
			}
			else {
				$sticky.removeClass('collapsed');
			}
		});

		this.stickynavDefault.on('click', (e) => {
			e.preventDefault();

			if (!this.stickynavList.hasClass('active')) {
				this.stickynavList.addClass('active');
			} else {
				this.stickynavList.removeClass('active');
			}
		});

		this.stickynavCollapsed.on('click', (e) => {
			e.preventDefault();

			if (!this.stickynavList.hasClass('active')) {
				this.stickynavList.addClass('active');
			} else {
				this.stickynavList.removeClass('active');
			}
		});

		// let $stickyNav = $('.sticky-nav'),
		// 	$btn = $('.sticky-nav__mainbtn', $stickyNav),
		// 	$arrow = $('.sticky-nav__scroll', $stickyNav),
		// 	$list = $('.sticky-nav__list', $stickyNav);

		// if ($stickyNav) {
		// 	$btn.on('click', function(){
		// 		let $this = $(this);
		// 		if ($this.hasClass('is-opened')) {
		// 			$list.removeClass('active');
		// 			$this.removeClass('is-opened');
		// 		} else {
		// 			$this.addClass('is-opened');
		// 			$list.addClass('active');
		// 		}
		// 	});

		// 	$arrow.on('click', function(){
		// 		let $this = $(this);
		// 		$btn.removeClass('scrollUp');
		// 		navbar.parents('.sticky-nav').removeClass('scrolled');

		// 		$this.addClass('scrollUp');
		// 	});
		// }

		// var current, currentScrollTop = 0,
		// 	navbar = $('.sticky-nav__mainbtn');

		// $(window).scroll(function () {
		// 	var scrollPos = $(window).scrollTop();
		// 	var navbarHeight = navbar.height();

		// 	currentScrollTop = scrollPos;

		// 	// if (c < currentScrollTop && a > b + b) {
		// 	if (scrollPos > navbarHeight + navbarHeight && !($list.hasClass('active'))) {
		// 		navbar.addClass("scrollUp");
		// 		navbar.parents('.sticky-nav').addClass('scrolled');
		// 		navbar.siblings('.sticky-nav__scroll').removeClass("scrollUp");
		// 	} else if (current > currentScrollTop && !(scrollPos <= navbarHeight)) {
		// 		navbar.removeClass("scrollUp");
		// 		navbar.parents('.sticky-nav').removeClass('scrolled');
		// 		navbar.siblings('.sticky-nav__scroll').addClass("scrollUp");
		// 	}
		// 	current = currentScrollTop;
		// });
	}
}
