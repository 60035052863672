'use strict';

import $ from 'jquery';
import enquire from 'enquire.js';

export default class MattersItem {
	constructor() {
		let $mattersItem = $('.matters-item'),
			$mattersOverlay = $('.matters-item__overlay', $mattersItem);

		window.emitter.on('fontResize', () => {
			setTimeout(function() {
				$mattersOverlay.matchHeight({
					byRow: false
				});
			}, 10);
		});

		enquire.register('screen and (max-width: ' + (767) + 'px)', {
			match: () => {
				$mattersOverlay.matchHeight({
					byRow: true
				});
			},
			unmatch: () => {
				$('.matters-item__overlay').removeClass('is-active');
			}
		});

		enquire.register('screen and (min-width: ' + (768) + 'px)', {
			match: () => {
				$mattersOverlay.matchHeight({
					byRow: false
				});

				$mattersItem.each(function() {
					var $this = $(this),
						mattersLink = $('a', $this),
						mattersOverlay = $('.matters-item__overlay', $this);

					mattersLink.on('mouseover', function() {
						mattersOverlay.addClass('is-active');
					});

					mattersLink.on('mouseleave', function() {
						mattersOverlay.removeClass('is-active');
					});
				});
			},
			unmatch: () => {
				$('.matters-item__overlay').removeClass('is-active');
			}
		});
	}
}
