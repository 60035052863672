'use strict';

import $ from 'jquery';
import enquire from 'enquire.js';

export default class RoadshowsItem {
	constructor() {
		let $roadshowsItem = $('.roadshows-item'),
			$roadshowsTriangle = $('.roadshows-item__triangle', $roadshowsItem),
			$roadshowsText = $('.roadshows-item__text', $roadshowsItem),
			$roadshowsDesc = $('.text-description', $roadshowsText);

		$(window).on('load', () => {
			var imageWidth = $roadshowsItem.outerWidth() + 10;
			$roadshowsTriangle.css("border-left", imageWidth + 'px solid transparent');
		});

		$(window).on('resize', () => {
			var imageWidth = $roadshowsItem.outerWidth() + 10;

			$roadshowsTriangle.css("border-left", imageWidth + 'px solid transparent');
		});

		enquire.register('screen and (max-width: ' + (1024 - 1) + 'px)', {
			match: () => {
				$roadshowsDesc.each(function() {
					if ($(this)[0].scrollHeight > $(this)[0].clientHeight) {
						$(this).addClass('ellipsis short');
					}
				});

				 $('.text-description.ellipsis').each(function () {
					var original = $(this).clone().addClass('original notruncation').removeClass('short').hide();
					$(this).after(original);

					//debugger;
					var shortText = '';
					shortText = $(this).html().trim().substring(0, 60) + '...';
					$(this).html(shortText);
				});
			},
			unmatch: () => {
				$roadshowsDesc.each(function() {
					$(this).removeClass('ellipsis short');
				});
			}
		});
	}
}
