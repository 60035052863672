'use strict';

import $ from 'jquery';
import enquire from 'enquire.js';
import SiteSearch from '../../molecules/site-search/site-search';

export default class header {
	constructor() {
		let $header = $('.header'),
			$headerTop = $('.header__top', $header),
			$headerBottom = $('.header__bottom', $header),
			$headerNav = $('.site-header__nav', $header),
			$primaryNav = $('.nav__lvl1', $headerNav),
			$lvl1 = $primaryNav.children(),
			$overlay = $('.site-header__overlay', $headerNav),
			windowWidth = $(window).width(),
			afterWidth = ((windowWidth - $primaryNav.outerWidth()) / 2),
			overlayWidth = afterWidth + 80,
			$mobileMenu = $('.js-mobile-menu', $headerBottom),
			$siteSearch = $('.site-header__search', $header),
			$searchMenu = $('.js-search-menu', $headerBottom),
			$window = $(window),
			$bell = $('.header__bell'),
			$bellToggle = $('.bell-notification', $bell);

			this.$header = $header;
			this.$siteSearch = $siteSearch;
			this.$primaryNav = $primaryNav,
			this.$lvl1 = $lvl1;
			this.collapsibleMenuInit = false;

		enquire.register('screen and (max-width: ' + (1023) + 'px)', {
			match: () => {

				// Mobile menu binding
				$mobileMenu.on('click', e => {
					e.preventDefault();

					if ($header.hasClass('menu-open')) {
						this.closeMobileMenu();
						$header.removeClass('menu-open');
						$mobileMenu.removeClass('is-active');
					} else {
						this.openMobileMenu();
						this.closeMobileSearch();
						this.bellClose();
						$header.removeClass('search-open');
						$header.removeClass('bell-open');
						$header.addClass('menu-open');
						$mobileMenu.addClass('is-active');
					}
				});

				// Mobile search binding
				$searchMenu.on('click', e => {
					e.preventDefault();

					if ($header.hasClass('search-open')) {
						this.closeMobileSearch();
						$header.removeClass('search-open');
					} else {
						this.openMobileSearch();
						this.closeMobileMenu();
						this.bellClose();
						$mobileMenu.removeClass('is-active');
						$header.removeClass('menu-open');
						$header.removeClass('bell-open');
						$header.addClass('search-open');
					}
				});

				$bellToggle.on('click', () => {
					if ($header.hasClass('bell-open')) {
						this.bellClose();
						$header.removeClass('bell-open');
					} else {
						this.bellOpen();
						this.closeMobileMenu();
						this.closeMobileSearch();
						$mobileMenu.removeClass('is-active');
						$header.removeClass('menu-open');
						$header.removeClass('search-open');
						$header.addClass('bell-open');
					}
				});

				if (!this.collapsibleMenuInit) {
					this.initCollapsibleMenu();
				}

				// if in microsite
				if ($('.tab-navigation').length) {
					$headerBottom.addClass('non-sticky');
				}

				//unbind font-resizer
				$('body').removeClass('font-large').removeClass('font-larger').addClass('font-normal');

			},
			unmatch: () => {
				$mobileMenu.off('click');
				$searchMenu.off('click');
				// $header.closeMobileSearch();
				$header.removeClass('search-open');
				$header.removeClass('menu-open');
				$header.removeClass('bell-open');
				this.bellClose();
				$headerBottom.removeClass('non-sticky');
			}
		});

		$(window).on('scroll', function(){
			if($(window).scrollTop() > 295){
				$header.addClass('is-sticky');
			}
			else{
				$header.removeClass('is-sticky');
			}
		});

		//desktop nav
		enquire.register("screen and (min-width:1024px)", {
			match() {
				// $window.on('scroll.stickyHeader', function() {
				// 	if ($window.scrollTop() >= $headerTop.outerHeight()) {
				// 		$header.addClass('is-sticky');
				// 	} else {
				// 		$header.removeClass('is-sticky');
				// 	}
				// 	return false;
				// }).trigger('scroll.stickyHeader');

				let navHeight = $primaryNav.height(),
					subMenuInt = 49;

				if ($('.mainContent').hasClass('firecode-template')) {
					if (navHeight > 100) {
						$('.mainContent').css('margin-top', '270px');
					}  else if (navHeight > 85) {
						$('.mainContent').css('margin-top', '251px');
					} else if (navHeight > 68) {
						$('.mainContent').css('margin-top', '232px');
					} else {
						$('.mainContent').css('margin-top', '213px');
					}
				} else {
					if (navHeight == 89) {
						$('.mainContent').css('margin-top', '314px');
					} else if (navHeight == 70) {
						$('.mainContent').css('margin-top', '295px');
					} else if (navHeight == 108) {
						$('.mainContent').css('margin-top', '333px');
					} else {
						$('.mainContent').css('margin-top', '276px');
					}
				}

				if (navHeight == 89) {
					// $('.mainContent').css('margin-top', '314px');
					$overlay.addClass('three-line');
					$overlay.removeClass('one-line');
					$overlay.removeClass('two-line');
					$overlay.removeClass('four-line');
					subMenuInt = 85;
				} else if (navHeight == 70) {
					// $('.mainContent').css('margin-top', '295px');
					$overlay.addClass('two-line');
					$overlay.removeClass('one-line');
					$overlay.removeClass('three-line');
					$overlay.removeClass('four-line');
					subMenuInt = 70;
				} else if (navHeight == 108) {
					// $('.mainContent').css('margin-top', '333px');
					$overlay.addClass('four-line');
					$overlay.removeClass('one-line');
					$overlay.removeClass('three-line');
					$overlay.removeClass('two-line');
					subMenuInt = 105;
				} else {
					// $('.mainContent').css('margin-top', '276px');
					$overlay.addClass('one-line');
					$overlay.removeClass('three-line');
					$overlay.removeClass('two-line');
					$overlay.removeClass('four-line');
					subMenuInt = 49;
				}

				$('.nav__lvl1 > li > a > span').each(function() {
					$(this).css("height", navHeight - 30);
				});

				var countLI = 0;

				$('.nav__lvl1 > li').each(function() {
					var degrees = 18,
						item = $(this),
						menu = item.parent(),
						subMenu = item.children('ul');

					countLI += 1;

					// Convert degrees to radians.
					var menu_radians = degrees * (Math.PI / 180),
						subMenu_radians = (degrees / 2) * (Math.PI / 180),
						menu_h = parseInt(menu.css('height'), 10),
						subMenu_h = parseInt(subMenu.css('height'), 10);

					// We need to factor these in to get an accurate offset.
					subMenu_h += parseInt(item.css('height'), 10);
					subMenu_h += parseInt(item.css('padding-top'), 10);
					subMenu_h += parseInt(item.css('padding-bottom'), 10);
					subMenu_h += parseInt(subMenuInt);

					// Get skew offsets.
					var menu_offset = menu_h * Math.atan(menu_radians),
						subMenu_offset = subMenu_h * Math.atan(subMenu_radians);

					// Put everything together.
					var offset = subMenu_offset - menu_offset;

					if (countLI < 5) {
						subMenu.css('left', offset*2.1);
					} else {
						subMenu.css('right', offset*-2.1);
					}

			  	});
			},
			unmatch() {
				// $window.off('scroll.stickyHeader');
				$('.nav__lvl1 > li > ul').css('left', "0");
			}
		});
	}

	initCollapsibleMenu() {

		this.$lvl1.map((i, ele) => {
			let $this = $(ele);

			// if ($this.find('.sub-menu').length) {
			if ($this.find('.nav__lvl2').length) {
				let $lvl1Controller = $('.lvl1-controller', $this);
				$lvl1Controller.on('click', e => {
					e.preventDefault();

					if ($this.hasClass('expanded')) {
						this.collapseChild($this, $('.nav__lvl2', $this));
						$this.removeClass('expanded');
					} else {
						this.expandChild($this, $('.nav__lvl2', $this));
						$this.addClass('expanded');
					}
				});

				let $lvl2Parent = $this.find('.nav__lvl2'),
				$lvl2 = $lvl2Parent.children();

				$lvl2.map((j, lvl2) => {
					let $this2 = $(lvl2);

					if ($this2.find('.nav__lvl3').length) {
						let $lvl2Controller = $('.lvl2-controller', $this2);

						$lvl2Controller.on('click', e => {
							e.preventDefault();

							if ($this2.hasClass('expanded')) {
								this.collapseChild($this2, $('.nav__lvl3', $this2));
								$this2.removeClass('expanded');
							} else {
								this.expandChild($this2, $('.nav__lvl3', $this2));
								$this2.addClass('expanded');
							}
						});
					}
				});
			}
		});

		this.collapsibleMenuInit = true;
	}

	openMobileMenu() {
		$('body').addClass('menu-open');
	}

	closeMobileMenu() {
		$('body').removeClass('menu-open');
	}

	openMobileSearch() {
		this.$siteSearch.stop().slideDown(350);
	}

	closeMobileSearch() {
		this.$siteSearch.stop().slideUp(350);
	}

	// expand child in mobile menu
	expandChild($parent, $child) {
		$child.slideDown();
	}

	// collapse child in mobile menu
	collapseChild($parent, $child) {
		$child.slideUp();
	}

	bellOpen() {
		$('.bell-notification').addClass('is-active');
	}

	bellClose() {
		$('.bell-notification').removeClass('is-active');
	}
}
