'use strict';

import $ from 'jquery';
import enquire from 'enquire.js';
import 'jquery-match-height';

export default class FirecodeGrid {
	constructor() {
		let $firecodeGrid = $('.firecode-grid'),
			$firecodeItem = $('.firecode-grid-item', $firecodeGrid),
			$firecodeWrapper = $('.firecode-grid-wrapper', $firecodeGrid),
			$firecodeItemWidth = $firecodeItem.outerWidth(),
			$firecodeContent = $('.firecode-grid-item__content', $firecodeItem),
			$firecodeTriangle = $('.firecode-grid-item__triangle', $firecodeItem),
			$firecodeOverlay = $('.firecode-grid-item__image-overlay', $firecodeItem);
			
		$firecodeWrapper.matchHeight();

		let triangleWidth = 30;

		$firecodeTriangle.css("border-left", ($firecodeItemWidth + triangleWidth) + 'px solid transparent');

		let resizeTriangle;

		resizeTriangle = function() {
			$firecodeItemWidth = $firecodeItem.outerWidth();
			$firecodeTriangle.css("border-left", ($firecodeItemWidth + triangleWidth) + 'px solid transparent');
		}

		window.addEventListener('resize', resizeTriangle);

		enquire.register('screen and (min-width: ' + (1024) + 'px)', {
			match: () => {
				$firecodeItem.each(function() {
					var $this = $(this),
						firecodeLink = $('a', $this),
						firecodeOverlay = $('.firecode-grid-item__image-overlay', $this);

					firecodeLink.on('mouseover', function() {
						firecodeOverlay.addClass('is-active');
						firecodeOverlay.removeClass('not-active');
					});

					firecodeLink.on('mouseleave', function() {
						firecodeOverlay.addClass('not-active');
						firecodeOverlay.removeClass('is-active');
					});
				});
			},
			unmatch: () => {
				$('.firecode-grid-item__image-overlay').css("height", "0");
			}
		});
	}
}
