'use strict';

import $ from 'jquery';
import 'slick-carousel';

export default class GalleryWidget {
	constructor() {
		let $galleryWrapper = $('.gallery-widget'),
			$galleryCarouselMain = $('.gallery-widget__main', $galleryWrapper),
			$galleryCarouselNav = $('.gallery-widget__nav', $galleryWrapper);

		$galleryCarouselMain.on('init', function(event, slick){
			$('[data-slick-index=1]').addClass("slick-slide__second");
			$('[data-slick-index=2]').addClass("slick-slide__third");
		});

		$galleryCarouselMain.slick({
			prevArrow: '<button class="slick-prev carousel-btn carousel-btn__prev" type="button"><strong class="icomoon-arrow-prev"></strong></button>',
			nextArrow: '<button class="slick-next carousel-btn carousel-btn__next" type="button"><strong class="icomoon-arrow-next"></strong></button>',
			swipe: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			fade: true,
			asNavFor: '.gallery-widget__nav'
		});

		$galleryCarouselNav.slick({
			swipe: true,
			slidesToShow: 8,
			slidesToScroll: 1,
			centerMode: false,
			centerPadding: '50px',
			focusOnSelect: true,
			arrows: false,
			asNavFor: '.gallery-widget__main',
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 6,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 1
					}
				}
			]
		});

		$galleryCarouselMain.on('afterChange', function(event, slick, currentSlide){
			$('.slick-slide').each(function() {
				if ($('.slick-slide').hasClass('slick-slide__second')) {
					$('.slick-slide').removeClass('slick-slide__second');
				}
				if ($('.slick-slide').hasClass('slick-slide__third')) {
					$('.slick-slide').removeClass('slick-slide__third');
				}
			});

			var secondSlide = currentSlide + 1,
				thirdSlide = currentSlide + 2,
				totalSlide = slick.slideCount - 1;

			if (secondSlide == totalSlide) {
				$('[data-slick-index= ' + totalSlide + ']').addClass("slick-slide__second");
				$('[data-slick-index=0]').addClass("slick-slide__third");
			} else if (secondSlide > totalSlide) {
				$('[data-slick-index=0]').addClass("slick-slide__second");
				$('[data-slick-index=1]').addClass("slick-slide__third");
			} else {
				$('[data-slick-index= ' + secondSlide + ']').addClass("slick-slide__second");
				$('[data-slick-index= ' + thirdSlide + ']').addClass("slick-slide__third");
			}
		});
	}
}
