'use strict';

import $ from 'jquery';

export default class FirecodeSearchWidget {
	constructor() {
		let $firecodeSearch = $('.firecode-search-widget'),
			$firecodeSearchChap = $('.firecode-search-widget--chapter', $firecodeSearch),
			$fireCodeFilter = $('.firecode-chapter-filter', $firecodeSearch),
			url = $fireCodeFilter.data('endpoint'),
			pageid = $fireCodeFilter.data('pageid'),
			data = { pageid },
			$keywordSearch = $('.firecode-search-widget--keyword__search', $firecodeSearch),
			$keywordSearchBtn = $('button', $keywordSearch),
			$chapterFilter = $('#chapterFilter', $fireCodeFilter),
			$clauseFilter = $('#clauseFilter', $fireCodeFilter),
			$clauseItem = $('.filter-item-clause', $fireCodeFilter),
			$chapterSearchBtn = $('button', $fireCodeFilter),
			$select = $('select', $fireCodeFilter),
			_self = this;

			const keywordURL = $keywordSearch.data('url');
			const chapterURL = $firecodeSearchChap.data('url');
			let chapterJSONdata;

		$select.map((i,ele) => {
			let $this = $(ele);

			let firstOption = $('option', $this).first().text(),
				firstValue = $('option', $this).first().val(),
				_clone = `<div class="dropdown-copy"><button type="button" value="${firstValue}">${firstOption}</button></div>`;

			$this.before(_clone);
		});

		let $copy = $('.dropdown-copy', $fireCodeFilter);

		$copy.map((i, ele) => {
        	let $this = $(ele),
        		$select = $this.next(),
        		$button = $this.find('button');

	        $select.on('click', (e) => {
				e.stopPropagation();

				if (!$this.hasClass('active')){
	        		$this.addClass('active');
				}
				else {
	        		$this.removeClass('active');
				}

				$('.dropdown-copy').not($this).removeClass('active');
	        });

			$select.on('change', () => {
				let $option = $select.find(':selected');

				$select.prev().find('button').text($option.text());
			});

			$(window).on('click', () => {
				if ($this.hasClass('active')){
					$this.removeClass('active');
				}
			});
		});

		let handleResults = (data) => {
			this.data = data;
			chapterJSONdata = data;

			for(let i= 0; i < data.length; i++) {
				$chapterFilter.append('<option value="' + data[i].chapterNo + '">' + data[i].chapterName + '</option>');
			}
		}

		this.getResults(url, data, handleResults);

		$chapterFilter.on('change', function(e) {
			let $this = $(this),
				$selected = $('option:selected', $this).val();

			let handleClause = (data) => {
				this.data = data;
				$clauseFilter.empty();

				let chapterNo = parseInt($selected);

				if (data[chapterNo].clauses.length > 0) {
					for (let i = 0; i < data[chapterNo].clauses.length; i++) {
						$clauseFilter.append('<option value="' + data[chapterNo].clauses[i].url + '">' + data[chapterNo].clauses[i].title + '</option>');
					}
					$('.filter-item-clause .dropdown-copy button').text(data[chapterNo].clauses[0].title);	
				} else {
					$clauseItem.removeClass('show');
					$clauseFilter.append('<option value="' + data[chapterNo].url + '">' + data[chapterNo].chapterName + '</option>');
				}
			}

			if ($selected !== "" ) {
				$clauseItem.addClass('show');
				handleClause(chapterJSONdata);
			} else {
				$clauseItem.removeClass('show');
			}
		});

		$('.keyword__input').on('keypress', function(e) {
			if (e.keyCode == 13 || e.which == 13) {
				e.preventDefault();

				$keywordSearchBtn.trigger('click');
			}
		});

		$keywordSearchBtn.on('click', function(e) {
			e.preventDefault();
			let $keywordInput = $('.keyword__input', $keywordSearch);

			let searchQuery = _self.makeSearchQuery($keywordInput, keywordURL);
			window.location.href = searchQuery;
		});

		$chapterSearchBtn.on('click', function(e) {
			e.preventDefault();

			let $chapSelected = $('option:selected', $chapterFilter).val(),
				// $subSelected = $clauseFilter.parent().find('.dropdown-copy button').text(),
				// $subFindDot = $subSelected.indexOf("."),
				// $clauseNum = $subSelected.substring(($subFindDot + 1), $subSelected.indexOf(" ")),
				goToChapterUrl = $('#clauseFilter option:selected').val();

			if ($chapSelected === "") { return; }

			// let searchQuery = _self.searchChapter($chapSelected, $clauseNum , chapterURL);
			window.location.href = goToChapterUrl;
		});
	}

	getResults(url, data, callback) {
		$.ajax({
			url,
			data,
			dataType: "json",
			type: "GET"
		}).done(callback);
	}

	makeSearchQuery(selector, pURL) {
		let url = window.location.origin,
			searchValue = $(selector).val(),
			pageURL = pURL,
			keywords = searchValue.split(" "),
			keywordLength = keywords.length,
			query = '';

		for (let i = 0; i < keywordLength; i++){
			query += keywords[i];
			query += "+";
		}

		query = query.slice(0, -1);
		let searchQuery = `${url}${pageURL}?q=${query}`;

		return searchQuery;
	}

	searchChapter(chapter, clauseter, pURL) {
		return pURL + '?chapter=' + chapter + "&clauseter=" + clauseter;
	}
}
