'use strict';

import $ from 'jquery';
import 'jquery-match-height';

export default class FirecodePagination {
	constructor() {
		let $firecodePagination = $('.firecode-pagination'),
			$paginationPrev = $('.firecode-pagination--prev', $firecodePagination), 
			$paginationNext = $('.firecode-pagination--next', $firecodePagination),
			prevHeight = $('span', $paginationPrev).outerHeight(),
			nextHeight = $('span', $paginationNext).outerHeight(),
			prevWidth = $('span', $paginationPrev).outerWidth(),
			nextWidth = $('span', $paginationNext).outerWidth();
			
		let reiszeWidth = function() {
			if ((prevHeight != nextHeight) && ($paginationPrev.length > 0 || $paginationNext.length > 0)) {
				setTimeout(function() {
					$paginationNext.matchHeight({
						target: $firecodePagination
					});
			
					$paginationPrev.matchHeight({
						target: $firecodePagination
					});
				}, 200);
			}

			if ($(window).width() < 1024) {
				if ((prevWidth != nextWidth) && ($paginationPrev.length > 0 || $paginationNext.length > 0)) {
					setTimeout(function() {
						if (prevWidth > nextWidth) {
							$paginationNext.css('width', $paginationPrev.outerWidth());
						} else {
							$paginationPrev.css('width', $paginationNext.outerWidth());
						}
					}, 200);
				}
			}
		};

		window.addEventListener('load', reiszeWidth);
		window.addEventListener('resize', reiszeWidth);

		if ($paginationPrev.length < 1) {
			$firecodePagination.addClass('pagination-align');

			if (prevHeight > 22 || nextHeight > 22) {
				$firecodePagination.addClass('pagination-twoline');
			} else {
				$firecodePagination.removeClass('pagination-twoline');
			}
		}

		if ($paginationNext.length < 1) {
			$firecodePagination.addClass('pagination-align-left');

			if (prevHeight > 22 || nextHeight > 22) {
				$firecodePagination.addClass('pagination-twoline');
			} else {
				$firecodePagination.removeClass('pagination-twoline');
			}
		}

		let resizeHeight = function() {
			if ((prevHeight != nextHeight) && ($paginationPrev.length > 0 || $paginationNext.length > 0)) {
				$paginationNext.matchHeight({ remove: true });
				$paginationPrev.matchHeight({ remove: true });

				setTimeout(function() {
					$paginationNext.matchHeight({
						target: $firecodePagination
					});
			
					$paginationPrev.matchHeight({
						target: $firecodePagination
					});
				}, 200);
			}

			setTimeout(function() {
				let height = $firecodePagination.height();

				$firecodePagination.removeClass('pagination-fourline pagination-threeline pagination-twoline');
				
				if (height > 80) {
					$firecodePagination.addClass('pagination-fourline');
				} else if (height > 70) {
					$firecodePagination.addClass('pagination-threeline');
				} else if (prevHeight > 24 || nextHeight > 24) {
					$firecodePagination.addClass('pagination-twoline');
				} else {
					$firecodePagination.removeClass('pagination-fourline pagination-threeline pagination-twoline');
				}
			}, 400);
		}
		
		window.addEventListener('load', resizeHeight);
		window.addEventListener('resize', resizeHeight);

	}
}
