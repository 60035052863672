'use strict';

import $ from 'jquery';
import 'slick-carousel';
import 'jquery-match-height';

export default class RoadshowsWidget {
	constructor() {
		let $roadshowsWrapper = $('.roadshows-widget'),
			$roadshowsItem = $('.roadshows-item', $roadshowsWrapper),
			$roadshowsCarousel = $('.roadshows-widget__items', $roadshowsWrapper),
			$roadshowsContent = $('.roadshows-item__content', $roadshowsItem),
			$roadshowsHeight = $('.roadshows-item__text', $roadshowsItem);

		$roadshowsCarousel.slick({
			speed: 700,
			swipe: true,
			slidesToShow: 3,
			slidesToScroll: 1,
			prevArrow: '.roadshow-arrow-prev',
			nextArrow: '.roadshow-arrow-next',
			infinite: false,
			responsive: [
				{
					breakpoint: 1023,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 560,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});

		$roadshowsItem.matchHeight();
		window.emitter.on('fontResize', () => {
			$roadshowsItem.matchHeight._update();
		});
	}
}
