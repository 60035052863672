'use strict';

import $ from 'jquery';
import 'fancybox';
import enquire from 'enquire.js';

export default class VideoWidget {
	constructor() {
		let $videoWrapper = $('.video-widget'),
			$videoContent = $('.video-widget__content', $videoWrapper),
			$videoDesc = $('.video-widget__text p', $videoContent);

		$('[data-fancybox]').fancybox({
			arrows: false,
			loop: false,
			buttons: [
				'close'
			],
			iframe : {
				preload : false,
				css: {
					width: '800px',
					height: '600px'
				}
			}
		});
	}
}
