'use strict';

import $ from 'jquery';
import 'slick-carousel';
import 'jquery-match-height';

export default class NewsWidget {
	constructor() {
		let $newsWrapper = $('.news-widget'),
    		$newsItem = $('.news-item', $newsWrapper),
			$newsCarousel = $('.news-widget__items', $newsWrapper);

		$newsCarousel.slick({
			speed: 700,
			swipe: true,
			slidesToShow: 3,
			slidesToScroll: 1,
			prevArrow: '.news-arrow-prev',
			nextArrow: '.news-arrow-next',
			infinite: false,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 560,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});

		$newsItem.matchHeight();
		window.emitter.on('fontResize', () => {
			$newsItem.matchHeight._update();
		});

		$(window).on('resize', () => {
			$newsItem.matchHeight();
		}).trigger('resize');
	}
}
