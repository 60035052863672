'use strict';

import $ from 'jquery';
import enquire from 'enquire.js';
import Cookies from 'js-cookie';

export default class TabNavigation {
	constructor() {
		let $tabNav = $('.tab-navigation'),
			sticky = $tabNav.offset().top,
			$tabs = $('.tabs', $tabNav),
			$header = $('.header');

		let expireInOneMin = new Date(new Date().getTime() + 1 * 60 * 1000);

		$('ul.tabs li').click(function(e) {
			let $this = $(this),
				link = $('.tab-link', $this),
				tab_name = $this.attr('data-name');

			$('.tab-link__border').removeClass('is-active');

			// Cookies.set('tab-name', tab_name, { expires: expireInOneMin });
			Cookies.set('tab-name', tab_name, { expires: 1 });
		});

		let tabCookie = Cookies.get('tab-name');
		if (tabCookie != undefined) {
			$('ul.tabs li').each(function() {
				let $this = $(this),
					tab_name = $this.attr('data-name');

				if (tab_name === tabCookie) {
					$('.tab-link__border', $this).addClass('is-active');
				} else {
					$('.tab-link__border', $this).removeClass('is-active');
				}
			});
		}

    	enquire.register('screen and (max-width: ' + (1023) + 'px)', {
			match: () => {
				$(window).on('scroll', () => {
					if ($(window).scrollTop() > (sticky - 100)) {
						$tabNav.addClass('sticky');
						$header.addClass('no-display');
					} else{
						$tabNav.removeClass('sticky');
						$header.removeClass('no-display');
					}
				});

				let totalTabWidth = 0;

				$('ul.tabs li').each(function() {
					let $this = $(this),
						tabWdith = $this.outerWidth();

					totalTabWidth += tabWdith + 65;
				});

				$tabs.css("width", totalTabWidth);
			},
			unmatch: () => {
				$tabNav.removeClass("sticky");
				$header.removeClass('no-display');
			}
		});

		enquire.register('screen and (min-width: ' + (1024) + 'px)', {
			match: () => {
				$tabNav.removeClass("sticky");
				$header.removeClass('no-display');
			},
			unmatch: () => {}
		});
	}
}
