'use strict';

import $ from 'jquery';
import Pikaday from 'pikaday';


export default class QuizReport {
  constructor() {
    let $dateFilter = $('.date-filter'),
			$dateInput = $('.date-filter__input'),
      reportApiUrl = $('.quiz-report').data('report');

		let pageURL = window.location.pathname;
    console.log(reportApiUrl);

    $.ajax({
       method: 'GET',
       contentType: 'application/json',
       dataType: 'json',
       url: reportApiUrl
      })
     .done(function (response) {
        generateQuizSelect(response);
        console.log('success');
     }).fail(function (err) {
        console.log(err);
     });


     function generateQuizSelect(response){
        let $quizSelect = $('#quizSelect'),
            selectList = '<option value="">Select</option>';


        $.each( response.quizzes, function( key, value ) {
          selectList +=  '<option value="'+value.id+'">'+value.title+'</option>'
        });

        $quizSelect.html(selectList);

     }

    $('select').each(function(){
			let $select = $(this),
				$label = $('<span class = "label"/>'),
				$icon = $('<span class = "icomoon-dropdown"/>'),
				$wrapper = $('<div class = "custom-select-wrapper"/>');
				$select.wrap( $wrapper );
				$select.after($label);
				$select.after($icon);
				$label.text($select.find(':selected').text());
		});

    $('.custom-select').on('change', function() {
      var $option = $(this).find(':selected'),
          text = $option.val(),
          $label = $('.label', $(this));

          if (text !== "") {
            $label.addClass('not-select');
          } else {
            $label.removeClass('not-select');
          }
          $label.text($option.text());
    });




      let $reportingTool = $('.reporting-tool'),
        $reportingFilter = $('.date-filter', $reportingTool),
        $reportingError = $('.date-filter--error', $reportingFilter),
        $reportingExport = $('.reporting-export');

      this.startPicker = new Pikaday({
        field: document.getElementById('reportingStartDate'),
        format: 'DD MMM YYYY',
        onSelect: () => {
          this.endPicker.setMinDate(this.startPicker.getDate());
        }
      });

      this.endPicker = new Pikaday({
        field: document.getElementById('reportingEndDate'),
        format: 'DD MMM YYYY',
        onSelect: () => {
          this.startPicker.setMaxDate(this.endPicker.getDate());
        }
      });

      $reportingExport.on('click', function(e) {
        e.preventDefault();
        console.log('click');

        let reportingEndDate = $('#reportingEndDate').val(),
          reportingStartDate = $('#reportingStartDate').val(),
          quizSelect = $('#quizSelect').val();

        let startDefined = false,
          endDefined = false,
          quizDefined = false;

        if (typeof reportingStartDate !== 'undefined' && reportingStartDate !== "" && reportingStartDate !== "-") {
          startDefined = true;
          reportingStartDate = moment(reportingStartDate).format('DD-MMM-YYYY');
        }

        if (typeof reportingEndDate !== 'undefined' && reportingEndDate !== "" && reportingEndDate !== "-") {
          endDefined = true;
          reportingEndDate = moment(reportingEndDate).format('DD-MMM-YYYY');
        }

        if(quizSelect != ""){
          quizDefined = true;
          $('.quiz-select .field-error-msg').addClass('no-display').html('');

        }else{
          $('.quiz-select .field-error-msg').removeClass('no-display').html('Please select a quiz');
        }

        if (!(startDefined && endDefined)){
          $('.date-filter--error', $reportingFilter).removeClass('no-display').html('Please fill up start and end date');
        }

        if (startDefined && endDefined && quizDefined) {
          $('.date-filter--error', $reportingFilter).addClass('no-display');
          window.location.href = getQuery(pageURL, reportingStartDate, reportingEndDate, quizSelect);
        }
      });



    function getQuery(pageURL, start, end, id) {
      return pageURL + '?start=' + start + '&end=' + end+ '&id=' + id;
    }
  }
}
