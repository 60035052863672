'use strict';

import $ from 'jquery';
import 'slick-carousel';

export default class NotificationDss {
	constructor() {
		let $notification = $('.notification__content'),
			$notificationBtn = $('.btn-toggle-notification'),
			$notificationCount = $('.icomoon-notification__count span', $notificationBtn);

		var $status = $('.notification__paging');

		$notification.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
			//currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
			var i = (currentSlide ? currentSlide : 0) + 1;
			$status.text(i + '/' + slick.slideCount);
			$notificationCount.html(slick.slideCount);
		});

		$notification.slick({
			infinite: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			draggable: false,
			swipe: false,
			easing: 'swing',
			speed: 700,
			dots: false,
			arrows: true,
			prevArrow: $('.pagination__btn.prev'),
			nextArrow: $('.pagination__btn.next'),
			responsive: [
				{
					breakpoint: 767,
					settings: {
						adaptiveHeight: true,
						swipe: true
					}
				},
				{
					breakpoint: 1024,
					settings: {
						adaptiveHeight: true,
						swipe: true
					}
				}
			]
		});

		// $btnClose.on('click', function (e) {
		// 	e.preventDefault;

		// 	$('.notification').slideUp(300);

		// 	$('.notification').removeClass('noticed');
		// 	$('.notification').removeClass('active');
		// 	// Cookie.set('notice', 'false');
		// 	$notification.slick('unslick');
		// });
	}
}
