'use strict';

import $ from 'jquery';
import enquire from 'enquire.js';

export default class SiteNavDss {
	constructor() {
		let $siteNav = $('.site-nav');
		let $siteNavItems = $('li', $siteNav);
		let $lvl1 = $('.site-nav__list', $siteNav);
		let $lvl1Links = $('> li', $lvl1);
		let $lvl2 = $('.site-nav__level2');
		let $lvl2Links = $('> li', $lvl2);

		this.$siteNav = $siteNav;
		this.$siteNavItems = $siteNavItems;

		$('> li > button', $lvl1).on('click', function(e){
			e.preventDefault();

			if(!$(this).parent().hasClass('expanded')){
				$(this).parent().addClass('expanded');
				$(this).next().slideDown(300);
			}
			else {
				$(this).parent().removeClass('expanded');
				$(this).next().slideUp(300);
			}
		});

		$('> li > button', $lvl2).on('click', function (e) {
			e.preventDefault();

			if (!$(this).parent().hasClass('expanded')) {
				$(this).parent().addClass('expanded');
				$(this).next().slideDown(300);
			} else {
				$(this).parent().removeClass('expanded');
				$(this).next().slideUp(300);
			}
		});

		let hoverTimeout = 0;

		enquire.register("screen and (min-width: 1024px)", {
			match: () => {
				$('.siteNav .expanded').removeClass('expanded');
				$('.site-nav__level2').hide();
				$('.site-nav__level3').hide();

				$lvl1Links.map((i, lvl1Link) => {
					let $this = $(lvl1Link);
					let target = $this.data('target');
					let $lvl1Link = $(lvl1Link);

					$this.on('focusin', () => {
						hoverTimeout = setTimeout(() => {
							$lvl1Links.removeClass('active');
							$this.addClass('active');
							this.expandChild($lvl1Link);
						}, 200);
					});

					// $this.on('focusout', () => {
					// 	$this.removeClass('active');
					// 	$this.removeClass('expanded');
					// });
				});

				$lvl2Links.map((j, lvl2Link) => {
					let $lvl2Link = $(lvl2Link);

					$lvl2Link.on('focusin', () => {
						if ($('.site-nav__level3', $lvl2Link).length) {
							// hoverTimeout = setTimeout(() => {
								$lvl2Links.removeClass('active');
								$lvl2Link.addClass('active');
								this.expandChild($lvl2Link);
							// }, 200);
						}
					});
					$lvl2Link.on('focusout', () => {
						$lvl2Link.removeClass('active');
						$lvl2Link.removeClass('expanded');
					});
				});
			}
		});
	}

	closeLevelTwo() {
		this.$siteNavItems.removeClass('active');
		this.$siteNavItems.removeClass('expanded');
	}

	expandLevelTwo(target) {
		this.$siteNav.addClass('lvl2-expanded');
		this.lvl2Expanded = true;

		let $target = $(target);

		$target.show(0, () => {
		    $target.addClass('is-shown');
		});
	}

	backLevelOne() {
		this.$siteNav.removeClass('lvl2-expanded');
		this.lvl2Expanded = false;

		setTimeout(() => {
		    $('.site-nav__lvl2').hide(0);
		}, 250);
	}

	expandChild($parent) {
		$parent.data('expanded', true);
		$parent.addClass('expanded');

		let $expandedSibling = $parent.siblings('.expanded');
		this.collapseChild($expandedSibling);

		let $lvl2 = $('.site-nav__lvl2', $parent);
		$lvl2.slideDown();

		$parent.find('> .site-nav__link > .icomoon').removeClass('icomoon-angle-down').addClass('icomoon-angle-up');
	}

	collapseChild($parent) {
		$parent.data('expanded', false);
		$parent.removeClass('expanded');
		let $lvl2 = $('.site-nav__lvl2', $parent);
		$lvl2.slideUp();

		$parent.find('> .site-nav__link > .icomoon').removeClass('icomoon-angle-up').addClass('icomoon-angle-down');
	}

	expandlvl3($child) {
		$child.data('expanded', true);
		$child.addClass('expanded');

		let $expandedSibling = $child.siblings('.expanded');
		this.collapseChild($expandedSibling);

		let $lvl3 = $('.site-nav__lvl3', $child);
		$lvl3.slideDown();

		$child.find('.icomoon').removeClass('icomoon-angle-down').addClass('icomoon-angle-up');
	}

	collapselvl3($child) {
		$child.data('expanded', false);
		$child.removeClass('expanded');
		let $lvl3 = $('.site-nav__lvl3', $child);
		$lvl3.slideUp();

		$child.find('.icomoon').removeClass('icomoon-angle-up').addClass('icomoon-angle-down');
	}
}
