'use strict';

import $ from 'jquery';
import 'leaflet';

export default class Mainmap {
	constructor() {
		let $btnIslandwide = $('.btn-wideview'),
			$mainmap = $('#mainmap');

		let apiToken = $mainmap.find('#onemapAPIToken').val();

		this.overlays = {
			aed: null,
			shelters: null,
			firestation: null,
			firepost: null,
			hq: null
		};

		// init basemap
		var center = L.bounds([1.56073, 104.11475], [1.16, 103.502]).getCenter();
        var map = L.map('mainmap',).setView([center.x, center.y], 12);

        var basemap = L.tileLayer('https://maps-{s}.onemap.sg/v3/Default/{z}/{x}/{y}.png', {
			detectRetina: true,
			maxZoom: 18,
			minZoom: 12
		});

        map.setMaxBounds([[1.56073, 104.1147], [1.16, 103.502]]);
		basemap.addTo(map);

		getLocation();

		function getLocation() {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(showPosition);
			}
		}

		function showPosition(position) {
			map.panTo([position.coords.latitude, position.coords.longitude]);
		}

		this.getThemeName(map, apiToken);

		//Island Wide View
		$btnIslandwide.on('click', function(e) {
			e.preventDefault();

			map.setZoom(12);
		});

		//Map Legend
		let legendTypes = $('.mainmap-legend__list input');

		legendTypes.map((i, ele) => {
			let $this = $(ele);

			$this.on('change', e => {
				let val = $this.val();

				if ($this.is(':checked')) {
					if(!map.hasLayer(this.overlays[val])) {

						map.addLayer(this.overlays[val]);
					}
				} else {
					if(map.hasLayer(this.overlays[val])) {
						map.removeLayer(this.overlays[val]);
					}
				}

			});
		});

		//Map Search
		let $searchBar = $('.mainmap-search__form'),
			$btnClear = $('.btn-clearsearch', $searchBar),
			$searchInput = $('.mainmap-search__input', $searchBar),
			$dropdownResult = $('.mainmap-search__list', $searchBar);

		let typingInterval = 0;

		$searchInput.on('keyup', function() {
			let textVal = $searchInput.val(),
			url = `https://developers.onemap.sg/commonapi/search?searchVal=${textVal}&returnGeom=Y&getAddrDetails=Y&pageNum=1`;

			clearTimeout(typingInterval);

			let searchVal = $(this).val();

			let searchList;

			if (searchVal.length >= 1) {
				$searchBar.addClass('expanded');

				typingInterval = setTimeout(function() {
					$.ajax({
						url,
						success: function(data){
							// console.log(data);

							if (data.results.length){
								searchList = '<ul>';

								for(let j = 0; j < data.results.length; j++) {
									let searchResult = data.results[j].SEARCHVAL;
									let lat = data.results[j].LATITUDE;
									let lng = data.results[j].LONGITUDE;

									searchList += `<li><button>${searchResult}</button></li>`;
								}
								searchList += `</ul>`;

							} else {
								searchList = '<p>No results found.</p>';
							}

							$('.mainmap-search__list').empty().html(searchList);

							$('button', $dropdownResult).on('click', function(e) {
								e.preventDefault();

								let $this = $(this),
									text = $this.text();

								$searchInput.val(text);

								$dropdownResult.addClass('hide');
								$dropdownResult.slideUp();

								for(let i = 0; i < data.results.length; i++) {

									if ( data.results[i].SEARCHVAL == text ) {
										let lat = data.results[i].LATITUDE,
											lng = data.results[i].LONGITUDE;

										map.setView([lat, lng], 17);
									}
								}
							})

						}
					});

					$dropdownResult.slideDown();
				},700);
			}
		});

		$btnClear.on('click', function() {
			$('input.mainmap-search__input').val("");

			$dropdownResult.hide();
			$searchBar.removeClass('expanded');
			map.setZoom(11);
		});
	}

	getThemeName(map, apiToken) {
		let url = `https://developers.onemap.sg/privateapi/themesvc/getAllThemesInfo?token=${apiToken}&moreInfo=Y`;

		$.ajax({
			url,
			method: "GET",
			dataType: "json",
			success: (result) => {
				let markerLayer;

				let hqIndex = getIndex(result, "scdfhq");
				let firepostIndex = getIndex(result, "firepost");
				let fireStationIndex = getIndex(result, "firestation");
				let sheltersIndex = getIndex(result, "civildefencepublicshelters");
				let aedIndex = getIndex(result, "public_access_aed_locations");

				let hqData = {
					queryName: result.Theme_Names[hqIndex].QUERYNAME,
					icon: 'ico-scdf-hq.png',
					overlay: 'hq'
				};

				let firepostsData = {
					queryName: result.Theme_Names[firepostIndex].QUERYNAME,
					icon: 'ico-fire-posts.png',
					overlay: 'firepost'
				};

				let firestationsData = {
					queryName: result.Theme_Names[fireStationIndex].QUERYNAME,
					icon: 'ico-fire-stations.png',
					overlay: 'firestation'
				};

				let sheltersData = {
					queryName: result.Theme_Names[sheltersIndex].QUERYNAME,
					icon: 'ico-civil-defence.png',
					overlay: 'shelters'
				};

				let aedData = {
					queryName: result.Theme_Names[aedIndex].QUERYNAME,
					icon: 'ico-public-access.png',
					overlay: 'aed'
				};

				getThemeData(aedData, apiToken, this.overlays);
				getThemeData(sheltersData, apiToken, this.overlays);
				getThemeData(firestationsData, apiToken, this.overlays);
				getThemeData(firepostsData, apiToken, this.overlays);
				getThemeData(hqData, apiToken, this.overlays);

				function getIndex(result, queryName) {
					let index = 0;

					for(; index < result.Theme_Names.length; index++){
						if(result.Theme_Names[index].QUERYNAME === queryName) {
							break;
						}
					}

					return index;
				}

				function getThemeData(themeData, apiToken, overlaysData) {
					let url = `https://developers.onemap.sg/privateapi/themesvc/retrieveTheme?queryName=${themeData.queryName}&token=${apiToken}`;

					$.ajax({
						url,
						method: "GET",
						dataType: "json",
						success: (result) => {
							let markers = [];

							let markerIcon = L.icon({
								iconUrl: '/Cwp/assets/images/map/' + themeData.icon,
								iconRetinaURL: '/Cwp/assets/images/map/' + themeData.icon,
								iconSize: [40, 40]
							})

							for(let i = 1; i < result.SrchResults.length; i++) {
								let name = result.SrchResults[i].NAME ? result.SrchResults[i].NAME : '',
									addnum = result.SrchResults[i].ADDRESSBLOCKHOUSENUMBER ? result.SrchResults[i].ADDRESSBLOCKHOUSENUMBER : '',
									addfloor = result.SrchResults[i].ADDRESSFLOORNUMBER ? result.SrchResults[i].ADDRESSFLOORNUMBER : '-',
									addstreet = result.SrchResults[i].ADDRESSSTREETNAME ? result.SrchResults[i].ADDRESSSTREETNAME : '',
									postal = result.SrchResults[i].ADDRESSPOSTALCODE ? result.SrchResults[i].ADDRESSPOSTALCODE : '',
									details = result.SrchResults[i].DESCRIPTION ? result.SrchResults[i].DESCRIPTION : '',
									popupContent = L.popup().setContent(`<h2>${name}</h2><div class="desc"><p>Address: ${addnum} ${addstreet}</p><p>${postal}</p><p>Level/Floor: ${addfloor}</p><p>${details}</p></div>`);

								let latLng = result.SrchResults[i].LatLng.split(',');
								let marker = L.marker(latLng, {icon: markerIcon}).bindPopup(popupContent);

								markers.push(marker);
							}

							markerLayer = L.layerGroup(markers);

							overlaysData[themeData.overlay] = markerLayer;
						},
						error: (err) => {
							alert("The routing services provided by OneMap is currently unavailable. Please try again later.");
						}
					});
				}
			},
			error: (err) => {
				alert("The routing services provided by OneMap is currently unavailable. Please try again later.");
			}
		});
	}
}


