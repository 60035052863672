'use strict';

import $ from 'jquery';

export default class ApplicationsWidget {
	constructor() {
		let $applicationWidget = $('.applications-widget'),
			$applicationItem = $('.applications-item', $applicationWidget),
			count = 0;

		$applicationItem.each(function() {
			let $this = $(this);

			count++;
		});

		if (count == 3 || count == 1) {
			$applicationItem.last().addClass('offset-md-3');
		}
	}
}
