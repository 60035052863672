// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import 'jquery-match-height';
import $ from 'jquery';
import Emitter from 'tiny-emitter';
import objectFitImages from 'object-fit-images';
import store from 'store2';		// Local/session storage cross browser wrapper
import BackToTop from '../_modules/atoms/back-to-top/back-to-top';
import WidgetTitle from '../_modules/atoms/widget-title/widget-title';
import FontResizer from '../_modules/molecules/font-resizer/font-resizer';
import BellNotification from '../_modules/molecules/bell-notification/bell-notification';
// import HotlineNumber from '../_modules/molecules/hotline-number/hotline-number';
import NewsItem from '../_modules/molecules/news-item/news-item';
import TabNavigation from '../_modules/molecules/tab-navigation/tab-navigation';
// import SiteSearch from '../_modules/molecules/site-search/site-search';
import Accordion from '../_modules/molecules/accordion/accordion';
import RoadshowsItem from '../_modules/molecules/roadshows-item/roadshows-item';
import DateFilter from '../_modules/molecules/date-filter/date-filter';
import MattersItem from '../_modules/molecules/matters-item/matters-item';
import Map from '../_modules/molecules/map/map';
import FirecodeAccordion from '../_modules/molecules/firecode-accordion/firecode-accordion';
import FirecodeClauseFilter from '../_modules/molecules/firecode-clause-filter/firecode-clause-filter';
import FirecodeAmendmentFilter from '../_modules/molecules/firecode-amendment-filter/firecode-amendment-filter';
import FirecodePagination from '../_modules/molecules/firecode-pagination/firecode-pagination';
import FirecodeLegend from '../_modules/molecules/firecode-legend/firecode-legend';
import SiteHeader from '../_modules/organisms/site-header/site-header';
import NewsWidget from '../_modules/organisms/news-widget/news-widget';
import RoadshowsWidget from '../_modules/organisms/roadshows-widget/roadshows-widget';
import VideoWidget from '../_modules/organisms/video-widget/video-widget';
import FooterLogo from '../_modules/organisms/footer-logo/footer-logo';
import SocialWidget from '../_modules/organisms/social-widget/social-widget';
import GalleryWidget from '../_modules/organisms/gallery-widget/gallery-widget';
import ApplicationsWidget from '../_modules/organisms/applications-widget/applications-widget';
import NewsReleasesWidget from '../_modules/organisms/news-releases-widget/news-releases-widget';
import Rte from '../_modules/organisms/rte/rte';
import FormWrapper from '../_modules/organisms/form-wrapper/form-wrapper';
import FormQuiz from '../_modules/organisms/form-quiz/form-quiz';
import FormQuiz2 from '../_modules/organisms/form-quiz-2/form-quiz-2';
import QuizReport from '../_modules/organisms/quiz-report/quiz-report';

import FirecodeSearchWidget from '../_modules/organisms/firecode-search-widget/firecode-search-widget';
import FirecodeGrid from '../_modules/organisms/firecode-grid/firecode-grid';
import ContentBlock from '../_modules/organisms/content-block/content-block';
import FirecodeClauseTable from '../_modules/organisms/firecode-clause-table/firecode-clause-table';
// import FirecodeAmendmentTable from '../_modules/organisms/firecode-amendment-table/firecode-amendment-table';
import GreetingCardWidget from '../_modules/organisms/greeting-card-widget/greeting-card-widget';

import StickyNavDss from '../_modules/molecules/sticky-nav-dss/sticky-nav-dss';
import SiteHeaderDss from '../_modules/organisms/site-header-dss/site-header-dss';
import NewsListing from '../_modules/organisms/news-listing/news-listing';
import Mainmap from '../_modules/molecules/mainmap/mainmap';
import HandbookListingItem from '../_modules/molecules/handbook-listing-item/handbook-listing-item';
import HandbookListing from '../_modules/organisms/handbook-listing/handbook-listing';
import HandbookFilter from '../_modules/organisms/handbook-filter/handbook-filter';


$(() => {
	// let isProd = false;

	window.emitter = new Emitter();

	new SiteHeaderDss();
	new FontResizer();
	new BackToTop();
	new BellNotification();
	// new SiteSearch();

	//Polyfill for object-fit
	objectFitImages();

	if ($('.sticky-nav').length) {
		new StickyNavDss($('.sticky-nav'));
	}

	// if ($('.hotline-number').length) {
	// 	new HotlineNumber();
	// }

	if ($('.news-widget').length) {
		new NewsWidget();
	}

	if ($('.news-item').length) {
		new NewsItem();
	}

	if ($('.video-widget').length) {
		new VideoWidget();
	}

	if ($('.footer-logo').length) {
		new FooterLogo();
	}

	if ($('.roadshows-item').length) {
		new RoadshowsItem();
	}

	if ($('.roadshows-widget').length) {
		new RoadshowsWidget();
	}

	// if ($('.social-widget').length) {
	// 	$('.social-widget').addClass('no-display');
	// 	new SocialWidget();
	// }

	if ($('.rte').length) {
		new Rte();
	}

	if ($('.tab-navigation').length) {
		new TabNavigation();
	}

	if ($('.accordion').length) {
		new Accordion();
	}

	if ($('.gallery-widget').length) {
		new GalleryWidget();

		$('#mainContent').addClass("gallery-overflow");
	}

	if ($('.date-filter').length) {
		new DateFilter();
	}

	if ($('.matters-item').length) {
		new MattersItem();
	}

	if ($('.news-releases-widget').length) {
		new NewsReleasesWidget();
	}

	if ($('.applications-widget').length) {
		new ApplicationsWidget();
	}

	if ($('.widget-title').length) {
		new WidgetTitle();
	}

	if ($('.form-wrapper').length){
		new FormWrapper();
	}

	if ($('.firecode-search-widget').length){
		new FirecodeSearchWidget();

		if ($('.rte').length) {
			$('.rte').each(function(e) {
				$(this).addClass('firecode');
			});
		}
	}

	if ($('.handbook-search-widget').length){
		new HandbookFilter();
	}

	if ($('.firecode-grid').length){
		new FirecodeGrid();
	}

	if ($('.firecode-accordion').length){
		new FirecodeAccordion();
	}

	if ($('.content-block').length){
		new ContentBlock();
	}

	if ($('.handbook-listing').length){
		new HandbookListing();
		new HandbookListingItem();
	}

	if ($('.firecode-clause-filter').length){
		new FirecodeClauseFilter();
	}

	if ($('.firecode-clause-table').length){
		new FirecodeClauseTable();
	}

	if ($('.firecode-amendment-filter').length){
		new FirecodeAmendmentFilter();
	}

	// if ($('.firecode-amendment-table').length){
	// 	new FirecodeAmendmentTable();
	// }

	if ($('.firecode-pagination').length){
		new FirecodePagination();
	}

	if ($('.form-quiz').length){
		// Clear store on form page
		// store(false);
		// console.log(store());

		new FormQuiz();
	}


	if ($('.form-quiz-2').length){
		// Clear store on form page
		// store(false);
		// console.log(store());

		new FormQuiz2();
	}

	if ($('.quiz-header').length){
		$('.side-nav').addClass('hide-content');
		$('.standard-content').removeClass('col-md-9');
		$('.standard-content').addClass('col-md-12');
	}

	if ($('.quiz-report').length){
		new QuizReport();
		$('.side-nav').addClass('hide-content');
		$('.standard-content').removeClass('col-md-9');
		$('.standard-content').addClass('col-md-12');
	}

	if ($('.quiz-results').length){

		if(store('error')) {
			$('.quiz-results--error').removeClass('no-display');
		} else {
			if (!store('quizResults')) {
				$('.quiz-results--fail').removeClass('no-display');
			} else {
				$('.quiz-results--pass').removeClass('no-display');
			}
		}
		
	}

	if ($('.quiz-certificate').length){
		$('.user-name').text(store('quizName'));
		$('.quiz-date').text(store('quizDate'));

		// Clear store upon certificate creation
		// store(false);
	}

	if ($('.map-iframe').length){
		new Map();
	}


	if ($('.firecode-legend').length) {
		new FirecodeLegend();
	}

	if ($('.greeting-card-widget').length) {
		new GreetingCardWidget();
	}

	if ($('.content-block').length) {
		$(document).ready(function() {
			setTimeout(function() {
				if (location.hash) {
					$("html, body").animate({
						scrollTop: $(location.hash).offset().top - 150
					}, 500);
				}
			}, 200);
		});
	}

	if ($('.mainContent').hasClass('firecode-template')) {
		$('.site-header__micronav').addClass('firecode-nodisplay');
	} else {
		$('.site-header__micronav').removeClass('firecode-nodisplay');

	}

	if($('.firecode-search-results-container').length){
		let get = {};
		let searchParameters = location.search.substr(1).split(/&/);

		for(let i = 0; i < searchParameters.length; i++){
			let tmp = searchParameters[i].split(/=/);
			if (tmp[0] != "") {
				get[decodeURIComponent(tmp[0])] = decodeURIComponent(tmp.slice(1).join("").replace("+", " "));
			}
		}

		let keyword = 'Search Result for "' + get.q + '"';
		$('.firecode-searchHeader').text(keyword);
	}

	if ($('.news-listing-wrapper').length) {
		new NewsListing();
	}

	if ($('.mainmap-container').length) {
		new Mainmap();
	}

	let $table = $('table');

	window.emitter.on('openAccordion', () => {
		$table.map((i, ele) => {
			let $this = $(ele);

			if (!$this.closest('.table-responsive').length) {
				if ($this.width() > $this.parent().width()) {
					$this.wrap('<div class="table-responsive"></div>');
				}
			}
		});
	});

	$(window).on('resize.tableResponsive', () => {
		$table.map((i, ele) => {
			let $this = $(ele);

			if (!$this.closest('.table-responsive').length) {
				if ($this.width() > $this.parent().width()) {
					$this.wrap('<div class="table-responsive"></div>');
				}
			}
		});
	}).trigger('resize.tableResponsive');

	if ($('.quiz-results--fail').length){
		$('.retry-quiz').on('click', function(e) {
			e.preventDefault();
			let pageURL = $(this).attr('href');

			window.location.href = pageURL;

			store("retryQuiz", true);
		});

		$('.end-quiz').on('click', function(e) {
			e.preventDefault();

			let pageURL = $(this).attr('href');

			window.location.href = pageURL;
		});
	}

	setTimeout(function(){
		// navigation
		$('.social-widget__media').slick({
			slidesToShow: 3,
			slidesToScroll: 1,
			asNavFor: '.slider-content',
			dots: true,
			focusOnSelect: true,
			infinite: true,
			prevArrow: '.social-arrow-prev',
			nextArrow: '.social-arrow-next',
			responsive: [
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 560,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});

		// content
		$('.social-widget__content').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			fade: true,
			asNavFor: '.slider-nav',
			responsive: [
				{
					breakpoint: 1023,
					settings: {
						adaptiveHeight: true
					}
				}
			]
		});

		// $('.social-widget__item').each(function() {
		// 	let $this = $(this),
		// 		$defaultImage = $('.social-widget__media').data('default-image');
		// 	if (!$('.attachment', $this).length) {
		// 		$('.social-widget__images', $this).append('<img class="attachment" src="' + $defaultImage + '">');
		// 	}
		// });

		// $('.social-widget').removeClass('no-display');
	// }, 9000);
	});


	/**
	 * detect IE
	 * returns version of IE or false, if browser is not Internet Explorer
	 */
	let detectIE = function() {
		var ua = window.navigator.userAgent;

		// Test values; Uncomment to check result …

		// IE 10
		// ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

		// IE 11
		// ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

		// Edge 12 (Spartan)
		// ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

		// Edge 13
		// ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

		var msie = ua.indexOf('MSIE ');
		if (msie > 0) {
			// IE 10 or older => return version number
			return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
		}

		var trident = ua.indexOf('Trident/');
		if (trident > 0) {
			// IE 11 => return version number
			var rv = ua.indexOf('rv:');
			return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
		}

		var edge = ua.indexOf('Edge/');
		if (edge > 0) {
			// Edge (IE 12+) => return version number
			return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
		}

		// other browser
		return false;
	};

	let version = detectIE();
	if (version === false) {
	} else {
		$('html').addClass('is-ie');
	}

	$('.match-height').matchHeight();
});
