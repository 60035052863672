'use strict';

import $ from 'jquery';
import 'fancybox';

export default class GreetingCardWidget {
	constructor() {
		let $greetingCard = $('.greeting-card-widget');

		$('input, textarea', $greetingCard).each(function() {
			let $this = $(this);

			if ($this.prop('required')) {
				$this.parents('.sf-fieldWrp').addClass('is-required');
			}

			$this.on('input', function(e) {
				if (!$this.is(':invalid')) {
					$this.removeClass('is-invalid');
				} else {
					$this.addClass('is-invalid');
				}
			});

			$this.on('invalid', function(e) {
				$this.addClass('is-invalid');
			});
		});

		$greetingCard.on("focusin", function() {
			$('.greeting-preview-btn').on('click', function(e) {
				e.preventDefault();
				$("#greetingFancybox").fancybox({
					'autoScale': true,
					'closeBtn': false,
					smallBtn: false,
					toolbar: false,
					hideOnOverlayClick: true,
					helpers: {
						overlay: {
							locked: false
						}
					},
					beforeShow: function() {
						let $greetingSender = $('.greeting-sender input#fromName', $greetingCard).val(),
							$greetingMsg = $('.greeting-message textarea', $greetingCard).val(),
							$greetingImg = $('.greeting-card-widget__image img', $greetingCard).attr('src');

						$greetingMsg = $greetingMsg.replace(/\r?\n/g, '<br/>');
						
						$('.greeting-card-fancybox').empty();
						$('.greeting-card-fancybox').append('<div><img src="' + $greetingImg + '" width="800" height="800"></div>');
						$('.greeting-card-fancybox').append('<span class="greeting-card-fancybox-close"></span>');
						$('.greeting-card-fancybox').append('<p>' + $greetingMsg + '</p>');
						$('.greeting-card-fancybox').append('<p>' + $greetingSender + '</p>');
					},
					afterClose: function() {
						$.fancybox.close();
						$( ".fancybox-is-open" ).remove();
					}
				}).trigger("click");
			});
	
			$('.greeting-card-fancybox').on('click', '.greeting-card-fancybox-close', function() {
				setTimeout(function() {
					$.fancybox.close();
					$( ".fancybox-is-open" ).remove();
				}, 10);
			});
		});

		/*
		* Fallback for iOS: HTML5 validation not working
		* This will also provide the basic validation if any HTML5 validation not working
		*/
		$('.greeting-submit-btn').on('click', function(e) {
			// e.preventDefault();
			let scrolledToError = false;

			$('.greeting-card-widget__form input').not('input[type="submit"]').not('input[type="checkbox"]').not('input[type="radio"]').not('input[type="file"]').not('input[type="hidden"]').map((i, ele) => {
			// $('.greeting-card-widget__form').map((i, ele) => {
				let $this = $(ele),
					$parent = $this.parents('.sf-fieldWrp'),
					emailRegex = /^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,4}$/;
				
				if ($parent.hasClass('is-required')) {
					if ($this.val().trim() === "") {
						setFieldInvalid($this);
						e.preventDefault();
						scrolledToError = true;
					// } else if ($this.attr('type') === 'email' && !(emailRegex.test($this.val()))) {
					} else if ($this.attr('type') === 'email') {
						let $emailString = $this.val(),
							$emailArray = $emailString.split(';'),
							emailValid = true;

						$.each( $emailArray, function( key, value ) {
							if (!(emailRegex.test(value.trim()))) {
								emailValid = false;
							}
						});

						if (emailValid) {
							$this.removeClass('is-invalid');
						}
					} else {
						$this.removeClass('is-invalid');
					}
				} else {
					if ($this.attr('type') === 'email') {
						let $emailString = $this.val(),
							$emailArray = $emailString.split(';'),
							emailValid = true;

						$.each( $emailArray, function( key, value ) {
							if (!(emailRegex.test(value.trim()))) {
								emailValid = false;
							}
						});

						if (emailValid) {
							$this.removeClass('is-invalid');
						}
					} else {
						$this.removeClass('is-invalid');
					}
				}
			});

			$('textarea', $('.greeting-card-widget__form')).map((i, ele) => {
				let $this = $(ele),
				$parent = $this.parents('.sf-fieldWrp');

				if ($parent.hasClass('is-required')) {
					if ($this.val().trim() === "") {
						setFieldInvalid($this);
						e.preventDefault();
						scrolledToError = true;
					} else if (!validateTextarea($this)) {
						setFieldInvalid($this);
						e.preventDefault();
						scrolledToError = true;
					} else {
						$this.removeClass('is-invalid');
					}
				} else {
					if ($this.val().trim() !== "" && !validateTextarea($this)) {
						setFieldInvalid($this)
						e.preventDefault();
						scrolledToError = true;
					} else {
						$this.removeClass('is-invalid');
					}
				}
			});

			// if (!scrolledToError) {
			// 	$('.greeting-card-widget__form').css('display', 'none');
			// 	$('.greeting-card-widget__thankyou').css('display', 'block');
			// } else {
			// 	$('html, body').animate({
			// 		scrollTop: $('.greeting-card-widget__form').offset().top - 150
			// 	});
			// }

			if (scrolledToError) {
				$('html, body').animate({
					scrollTop: $('.greeting-card-widget__form').offset().top - 150
				});
			}
		});

		$('.greeting-send-btn').on('click', function(e) {
			// location.reload();
			$('html, body').animate({
				scrollTop: $('.greeting-card-widget').offset().top - 350
			});
		});

		function setFieldInvalid($field) {
			$field.addClass('is-invalid');
		}

		function validateTextarea($textarea) {
			
			let minLength;
			let maxLength;
			let $textValue = $textarea.val();

			if ($textValue.indexOf("<script>") > -1 || $textValue.indexOf("script") > -1) {
				return false;				
			} else if (typeof $textarea.attr('minlength') !== 'undefined' && typeof $textarea.attr('maxlength') !== 'undefined') {
				minLength = parseInt($textarea.attr('minlength'));
				maxLength = parseInt($textarea.attr('maxlength'));
	
				return ($textarea.val().trim().length >= minLength && $textarea.val().trim().length <= maxLength);
			} else if (typeof $textarea.attr('minlength') !== 'undefined') {
				minLength = parseInt($textarea.attr('minlength'));
	
				return ($textarea.val().trim().length >= minLength);
			} else if (typeof $textarea.attr('maxlength') !== 'undefined') {
				maxLength = parseInt($textarea.attr('maxlength'));
	
				return ($textarea.val().trim().length <= maxLength);
			} else {
				return true;
			}
		}
	}
}
