'use strict';

import $ from 'jquery';

export default class FirecodeClauseTable {
	constructor() {
		let $firecodeTable = $('.firecode-clause-table'),
			$firecodeTableTable = $('.firecode-clause-table__table', $firecodeTable),
			$firecodeHeader = $('.firecode-clause-table__table--header', $firecodeTableTable),
			$fireCodeItemWrapper = $('.firecode-clause-table__table--content', $firecodeTableTable),
			$fireCodeItem = $('.firecode-clause-table__table--item', $firecodeTableTable);

		this.$firecodeTable = $firecodeTableTable;
		this.$firecodeHeader = $firecodeHeader;
		this.$fireCodeItemWrapper = $fireCodeItemWrapper;
		this.$fireCodeItem = $fireCodeItem;

		// data for api
		let url = $firecodeTableTable.data('endpoint');

		let data = {};

		this.url = url;
		this.data = data;

		$('a', $firecodeTable).click(function (e) {
			e.preventDefault();
			// console.log('28');
			var href = $(this).attr('href');
			// console.log('href', href);
			if (href === '#'){
				scrollToID(id, 1000);
			} else{
				$.cookie('scroll', id);
				window.location.href = href;
			}
		});
	}
}
