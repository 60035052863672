'use strict';

import $ from 'jquery';

export default class WidgetTitle {
	constructor() {
		let $widgetTitle = $('.widget-title'),
			$widgetPrevBtn = $('.carousel-arrow-prev', $widgetTitle),
			$widgetNextBtn = $('.carousel-arrow-next', $widgetTitle);

		$widgetPrevBtn.on('click', (e) => {
			e.preventDefault();
		});

		$widgetNextBtn.on('click', (e) => {
			e.preventDefault();
		});

	}
}
