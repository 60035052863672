'use strict';

import $ from 'jquery';
import store from 'store2';		// Local/session storage cross browser wrapper
import moment from 'moment';
import 'fancybox';

export default class FormQuiz2 {

	constructor() {

		let $formContainer = $('.form-quiz'),
			$formRegister = $('.form-quiz__register', $formContainer),
			$formQuestions = $('.form-quiz__questions', $formContainer),
			$dataQ = $('.form-quiz__questions--data', $formQuestions),
			timerStatus = false,
			pageURL = $dataQ.data('result-page'),
			interval = 0;

		let _this = this;


		//Get Questions from API
		let $dataQuestions = $('.form-quiz__questions--data', $formQuestions),
			questionsApiUrl = $dataQuestions.data('endpoint'),
			mandatoryList = [],
			optionalList = [],
			newList = [];


			$.ajax({
				 method: 'GET',
				 contentType: 'application/json',
				 dataType: 'json',
				 url: questionsApiUrl
			 	})
			 .done(function (response) {
					generateQuestions(response);
			 }).fail(function (err) {
				 	console.log(err);
			 });

			 function generateQuestions(response){
			 	let questionsList = '';

				$('#quizTimer').val(response.timer);
				$('#quizID').val(response.id);
				$('#quizTitle').val(response.title);


			 	$.each( response.questions, function( key, value ) {
			 		let num = key + 1;
			 		let qnsIndex = value.index;

			 		if(value.mandatory){
			 			questionsList += '<div class="field-group field-group-radio required" data-qns-index="' + qnsIndex + '">';
			 			questionsList += '<p>Question ' + num + '</p>';

			 		}else{
			 			questionsList += '<div class="field-group field-group-radio" data-qns-index="' + qnsIndex + '">';
			 			questionsList += '<p>Question ' + num + '</p>';

			 		}

			 		questionsList += '<strong class="question-text">' + value.question + '</strong>';

			 		if (value.image) {
			 			questionsList += '<span class="question-image"><img src="' + value.image + '"></img></span>';
			 		}

			 		$.each( value, function( index, choices ) {
			 			$.each( value.choices, function( e, option ) {
			 				// let	  qnsName = "question" + num;
			 				questionsList += '<label>';
			 				questionsList += '<input class="quiz-question" type="radio" name="' + qnsIndex + '" value="' + option.option + '">';
			 				questionsList += '<span>' + option.text + '</span>';
			 				questionsList += '</label>';
			 			});

			 			questionsList += '<span class="field-error-msg no-display">Please choose an option</span>';
			 			questionsList += '</div>';

			 			return false;
			 		});
		 	});

		 	$('.questions-list').html(questionsList);

			//If user is retrying quiz, remove registration form
			if (store("retryQuiz")) {
				$('.form-quiz__register').addClass('no-display');
				$('.form-quiz__questions').removeClass('no-display');
				store("retryQuiz", false);

				$('html, body').animate({
					scrollTop: $('.quiz-header').offset().top - 180
				});

				let timerDuration = $('#quizTimer').val();
				if(!timerStatus){
					startTimer(timerDuration, pageURL);
				}
				else {
					stopTimer(timerDuration, pageURL);
				}

			}else{
				//clear local storage if not retrying
				store(false);
			}
		}


		//custom select
		$('select').each(function(){
			let $select = $(this),
				$label = $('<span class = "label"/>'),
				$icon = $('<span class = "icomoon-dropdown"/>'),
				$wrapper = $('<div class = "custom-select-wrapper"/>');
				$select.wrap( $wrapper );
				$select.after($label);
				$select.after($icon);
				$label.text($select.find(':selected').text());
		});

		$('.custom-select').on('change', function() {
			var $option = $(this).find(':selected'),
				text = $option.val(),
				$label = $('.label', $(this));

				if (text === "Others") {
					let $others = $(this).parent().find('.others-input'),
							$othersInput = $others.find('input');

					$others.removeClass('no-display');
					$others.addClass('is-required');
					$othersInput.attr('required', 'required');
					$othersInput.removeAttr('disabled');

					if($(this).parent().find('.ep-input')){
						let $ep = $(this).parent().find('.ep-input'),
								$epInput = $ep.find('input');

						$ep.addClass('no-display');
						$ep.removeClass('is-required');
						$epInput.removeAttr('required');
						$epInput.attr('disabled', 'disabled');
					}

				} else if (text === "EP Day") {
					let $ep = $(this).parent().find('.ep-input'),
							$epInput = $ep.find('input');

							$ep.removeClass('no-display');
							$ep.addClass('is-required');
							$epInput.attr('required', 'required');
							$epInput.removeAttr('disabled');

							if($(this).parent().find('.others-input')){

								let $others = $(this).parent().find('.others-input'),
										$othersInput = $others.find('input');

								$others.addClass('no-display');
								$others.removeClass('is-required');
								$othersInput.removeAttr('required');
								$othersInput.attr('disabled', 'disabled');
						}
				}

				else {
					if($(this).parent().find('.others-input')){
						let $others = $(this).parent().find('.others-input'),
								$othersInput = $others.find('input');
						$others.addClass('no-display');
						$others.removeClass('is-required');
						$othersInput.removeAttr('required');
						$othersInput.attr('disabled', 'disabled');
					}

					if($(this).parent().find('.ep-input')){
						let $ep = $(this).parent().find('.ep-input'),
								$epInput = $ep.find('input');
						$ep.addClass('no-display');
						$ep.removeClass('is-required');
						$epInput.removeAttr('required');
						$epInput.attr('disabled', 'disabled');
					}
				}

				if (text !== "") {
					$label.addClass('not-select');
				} else {
					$label.removeClass('not-select');
				}

				$label.text($option.text());
		});

		//mandatory fields
		$('input, select', $formRegister).each(function() {
			let $this = $(this);
			if ($this.hasClass('required')) {
				$this.parents('.field-group').addClass('is-required');
			}
		});


		//Submit Register Form
		$('.register-submit').on('click', e => {
			e.preventDefault();

			let validateCheck = false,
					$this = $(this);

			$('.form-quiz__register input').not('input[type="submit"]').not('input[type="checkbox"]').not('input[type="radio"]').not('input[type="file"]').not('input[type="hidden"]').map((i, ele) => {
				let $this = $(ele),
						$parent = $this.parents('.field-group');

				if ($parent.hasClass('is-required')) {
					validateCheck = this.validateMandatory($this,$parent,validateCheck);
				} else {
					if ($this.val().trim() !== "") {
						if(this.validateInput($this)){
							$parent.find('.field-error-msg').addClass('no-display');
						}else{
							$parent.find('.field-error-msg').removeClass('no-display').html('Please enter the correct format for '+$this.attr("name") +'.');
							validateCheck = true;
						}
					}
				}
			});

			$('select', $formRegister).map((i, ele) => {
				let $this = $(ele),
				$parent = $this.parents('.field-group');

				if ($parent.hasClass('is-required')) {
					if ($this.val().trim() === "") {
						$parent.find('.field-error-msg').removeClass('no-display').html('Please select '+$this.attr("name") +'.');
						validateCheck = true;
					} else {
						$parent.find('.field-error-msg').addClass('no-display');
					}
				}
			});

			if (!validateCheck) {
				$('.field-group').each(function(){
						if($(this).hasClass('custom-select')){
							let input = $(this).find('select'),
									inputName = input.attr('id'),
									inputValue = input.val();
							if(inputValue != null){
								store(inputName, inputValue);
							}else{
								store(inputName, "");
							}
						}else{
							let input = $(this).find('input'),
									inputName = input.attr('id'),
									inputValue = input.val();
							if(inputValue != ""){
								store(inputName, inputValue);
							}else{
								store(inputName, "");
							}
						}
				});



				$('.form-quiz__register').addClass('no-display');
				$('.form-quiz__questions').removeClass('no-display');

				$('html, body').animate({
					scrollTop: $('.quiz-header').offset().top - 180
				});

				let timerDuration = $('#quizTimer').val();

				if(!timerStatus){
					startTimer(timerDuration, pageURL);
				}
				else {
					stopTimer(timerDuration, pageURL);
				}
			}
		});


		$('.questions-submit').on('click', e => {
			e.preventDefault();
			let $formQuestions = $('.form-quiz__questions'),
				$dataQuestions = $('.form-quiz__questions--data', $formQuestions),
				url = $dataQuestions.data('endpoint'),
				emptyField = false,
				chosenAnswers = [],
				quizID = $('#quizID').val(),
				quizTitle = $('#quizTitle').val();


			$('.field-group-radio').each(function(e) {
				let $this = this,
					$errorField = $('.field-error-msg', $this),
					selectedAns,
					qns,
					chosen = {};

					qns = $(this).data('qns-index');
					chosen.questionNo = qns;

					if($(this).find('input[type="radio"]:checked').length > 0) {
						$errorField.addClass('no-display');
						selectedAns = $(this).find('input[type="radio"]:checked').val();
						selectedAns = parseInt(selectedAns, 10);
						chosen.answerNo = selectedAns;
						chosenAnswers.push(chosen);
					} else {
						if($(this).hasClass('required')){
							$errorField.removeClass('no-display');
							emptyField = true;
						}else{
							chosen.answerNo = "";
							chosenAnswers.push(chosen);
						}
					}

			});

			if (emptyField) {
				return false;
			} else {

				let timerDuration = $('#quizTimer').val();
				getTimeUsed(timerDuration);

				let registerApiUrl = $dataQuestions.data('register');

				let registerArray = {};
				registerArray['quizTitle'] = quizTitle;
				registerArray['quizId'] = quizID;
				registerArray['name'] = store("quizUserName");
				registerArray['gender'] = store("quizGender");
				registerArray['age'] = store("quizAge");
				registerArray['contact'] = store("quizContact");
				registerArray['email'] = store("quizEmail");
				registerArray['race'] = store("quizRace");
				registerArray['raceOthers'] = store("quizRaceOthers");
				registerArray['nationality'] = store("quizNationality");
				registerArray['nationalityOthers'] = store("quizNationalityOthers");
				registerArray['venue'] = store("quizVenue");
				registerArray['venueEp'] = store("quizVenueEp");
				registerArray['venueOthers'] = store("quizVenueOthers");
				registerArray['information'] = store("quizInformation");
				registerArray['informationEp'] = store("quizInformationEp");
				registerArray['informationOthers'] = store("quizInformationOthers");
				// registerArray['datetime'] = moment(new Date()).format('DD-MM-YYYY');
				registerArray['timeTaken'] = store("timeTaken");
        registerArray['chosenAnswers'] = chosenAnswers;
				// console.log(JSON.stringify(registerArray));

        $.ajax({
           method: 'POST',	//GET for testing, POST for PROD
           contentType: 'application/json',
           dataType: 'json',
           url: registerApiUrl,
           data: JSON.stringify(registerArray)
         })
         .done(function (response) {
          //response = response from backend, no need to JSON.parse()
          generateResults(response);

         }).fail(function (err) {
           console.log(err);
         });

			}
		});


		function getTimeUsed(timerDuration){
			let countdowntimer = $('.countdown-timer').text().split(':');
			let currentminutes = parseInt(countdowntimer[0], 10);
			let currentseconds = parseInt(countdowntimer[1], 10);
			let timer = timerDuration.split(':');
			let minutes = parseInt(timer[0], 10);
			let seconds = parseInt(timer[1], 10);

			let timerDurationinSecs = currentminutes*60 + currentseconds,
					totaltime = minutes*60 + seconds,
					timeUsed = totaltime - timerDurationinSecs,
					timeUsedMinutes = parseInt(Math.floor(timeUsed / 60) , 10),
					timeUsedSecs = parseInt(timeUsed % 60 , 10);
					timeUsedSecs = (timeUsedSecs < 10) ? '0' + timeUsedSecs : timeUsedSecs;
					timeUsedMinutes = (timeUsedMinutes < 10) ? '0' + timeUsedMinutes : timeUsedMinutes;

					// console.log(timerDurationinSecs);

			timeUsed = timeUsedMinutes +':'+timeUsedSecs;
			store("timeTaken", timeUsed);
		}

		function startTimer(timerDuration, pageURL) {
			//interval for seconds
			interval = setInterval(function() {
				//timer will be [hour, minute, second]
				let timer = timerDuration.split(':');
				let minutes = parseInt(timer[0], 10);
				let seconds = parseInt(timer[1], 10);
				//reduce second by one
				--seconds;
				//calculate new minute
				minutes = (seconds < 0) ? --minutes : minutes;

				seconds = (seconds < 0) ? 59 : seconds;
				seconds = (seconds < 10) ? '0' + seconds : seconds;
				minutes = (minutes < 0) ? 59 : minutes;
				minutes = (minutes < 10) ? '0' + minutes : minutes;

				timerDuration = minutes + ':' + seconds;
				$('.countdown-timer').html(timerDuration);

				if (minutes === '00' && seconds === '00') {
					clearInterval(interval);

					$('.countdown-timer--overlay').removeClass('no-display');
					$('.countdown-timer').addClass('no-display');
				}
			}, 1000);
		}

		function stopTimer(timerDuration, pageURL) {
			clearInterval(interval)
			$('.countdown-timer').addClass('no-display');
		}

    function generateResults(response){
        let totalScore = response.score,
            isPass = response.isPass,
            remarks = response.remarks,
						error = response.error;

         // console.log(remarks);

				if(error) {
					store('error', error);
					window.location.href = pageURL;
				} else {
					if (isPass) {
						$('.questions-mark').text(totalScore);
						$('.questions-pass').removeClass('no-display');
	
						$('.quiz-submit-btn').addClass('no-display');
						$('.quiz-submit-next').removeClass('no-display');
	
						store('quizDate', moment(new Date()).format('DD MMM YYYY'));
	
						let cert = response.certificate;
	
						$('.questions-pass__cert').click(function(){
							var certTab = window.open();
							certTab.document.write('<img src="data:image/png;base64,'+cert+'" width="800px">');
							certTab.document.location = "#";
						});
	
						timerStatus = true;
						let timerDuration = $('#quizTimer').val();
	
						if(!timerStatus){
							startTimer(timerDuration, pageURL);
						}
						else {
							stopTimer(timerDuration, pageURL);
						}
	
						$('html, body').animate({
							scrollTop: $('.quiz-header').offset().top - 180
						});
	
						for(var i = 0; i < remarks.length; i++)
						{
							let text = remarks[i].text,
									isCorrect = remarks[i].isCorrect,
									question = $(".questions-list").find("[data-qns-index='" + remarks[i].qns + "']");
	
							if( isCorrect){
								question.find('input[type="radio"]:checked').closest('label').append('<span class="quiz-question-correct"> - ' + remarks[i].text + '</span>')
	
							}else{
								question.find('input[type="radio"]:checked').closest('label').append('<span class="quiz-question-wrong"> - ' + remarks[i].text + '</span>')
	
							}
						}
						$('.quiz-submit-next').on('click', e => {
							e.preventDefault();
							store('quizResults', true);
							window.location.href = pageURL;
						});
					}
	
					else {
						$('.quiz-question-wrong, .quiz-question-correct').addClass('no-display');
						$('.quiz-results--fail').removeClass('no-display');
						store('quizResults', false);
						window.location.href = pageURL;
					}
				}

        
    }

	}


	validateInput($input) {
		if (typeof $input.attr('type') === 'undefined') return true;
		let isValid = true,
		regex;
		switch ($input.attr('type')) {
			case 'tel':
				regex = /^[0-9]*\-?[0-9]*$/;
				break;
			case 'email':
				regex = /^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,4}$/;	// pattern from Sitefinity 9.2
				break;
			case 'number':
				regex = /^[0-9]{4}$/;
				break;
			default:
				regex = new RegExp('^' + $input.attr('pattern') + '$');

		}

		isValid = (regex.test($input.val()));
		return isValid;
	}

	setFieldInvalid($field, scrollToError) {
		$field.addClass('is-invalid');

		if (scrollToError) {
			$('html, body').animate({
				scrollTop: $field.offset().top - $('.header').outerHeight() - 30
			});
		}
	}

	validateMandatory($this, $parent,validateCheck){
		let  text =  $this.attr("name");

		if ($this.val().trim() === "") {
			if($parent.hasClass('others-input') || $parent.hasClass('ep-input')){
					let text = $parent.parent().find('.custom-select select').attr("name");
					$parent.find('.field-error-msg').removeClass('no-display').html('Please enter '+ text +'.');
			}else{
					$parent.find('.field-error-msg').removeClass('no-display').html('Please enter '+ text +'.');

			}
			validateCheck = true;
		} else {
			if($this.val().indexOf("<") != -1 || $this.val().indexOf(">") != -1 || $this.val().indexOf(";") != -1) {
				$parent.find('.field-error-msg').removeClass('no-display').html('Please enter the correct format for '+ text +'.');
				validateCheck = true;
			} else if(this.validateInput($this)){
				$parent.find('.field-error-msg').addClass('no-display');
			}else{
				$parent.find('.field-error-msg').removeClass('no-display').html('Please enter the correct format for '+ text +'.');
				validateCheck = true;
			}
		}
		return validateCheck;
	}


	getResults(url, callback){
		$.ajax({
			url,
			dataType: 'json',
			type: 'GET',
		}).done(callback);
	}
}
