'use strict';

import $ from 'jquery';
import FirecodeAmendmentTable from '../../organisms/firecode-amendment-table/firecode-amendment-table';

export default class FirecodeAmendmentFilter {
	constructor() {
		let $amendmentFilter = $('.firecode-amendment-filter'),
			$amendmentSelected = $('.firecode-amendment-filter__selected', $amendmentFilter),
			$amendmentSearch = $('.firecode-amendment-filter__search', $amendmentFilter),
			$amendmentOptions = $('.options', $amendmentFilter);

		let pageURL = window.location.pathname;

		$amendmentSelected.on('click', (e) => {
			e.stopPropagation();

			if(!$amendmentOptions.hasClass('show')){
				this.showOptions($amendmentOptions, $('strong', $amendmentSelected));
			}
			else {
				this.hideOptions($amendmentOptions, $('strong', $amendmentSelected));
			}
		});

		let firecodeAmendment = new FirecodeAmendmentTable();

		let renderResults = (data) => {
    		let amendmentItemTemplate = $('#dotAmendmentItem').html();
			let tableContent = '';

			tableContent = doT.template(amendmentItemTemplate)(data);
			firecodeAmendment.$fireCodeItemWrapper.html(tableContent);
    	}

		let parseResults = (response) => {
			this.resultsData = response;
			let resultsCopy = [];

			// resultsCopy = cloneResults(this.resultsData);
			// filterResults(response, resultsCopy);
			renderResults(response);
		}

		let chapterNo = $('li', '.firecode-amendment-filter .options').first().data('chapterno');
		let data = { chapterNo };

		// first ajax load
		this.getResult(firecodeAmendment.url, data, parseResults);
		// this.getResult(firecodeAmendment.url, firecodeAmendment.data, parseResults);

		// let chapterNo;

		// options clicks handler
		$('li a', $amendmentOptions).map((i,ele) => {
			let $this = $(ele);

			$this.on('click', (e) => {
				e.preventDefault();
				e.stopPropagation();

				$('span', $amendmentSelected).text($this.text());
				this.hideOptions($amendmentOptions, $('strong', $amendmentSelected));

				chapterNo = $this.parent().data('chapterno');

				// window.location.href = getQuery(pageURL, chapterNo);

				// this.getResult(firecodeAmendment.url, firecodeAmendment.data, parseResults);
			});
		});

		$amendmentSearch.on('click', e => {
			e.preventDefault();

			let data = { chapterNo };

			this.getResult(firecodeAmendment.url, data, parseResults);
		});

		// function getQuery(pageURL, chapterNo) {
		// 	return pageURL + '?chapterNo=' + chapterNo;
		// }
	}

	showOptions($list, $icon){
		$list.addClass('show');
		$icon.addClass('rotate');
	}

	hideOptions($list, $icon){
		$list.removeClass('show');
		$icon.removeClass('rotate');
	}

	// getResult(url, data, callback){
	// 	console.log('data', data);
	// 	$.ajax({
	// 		url,
	// 		data,
	// 		dataType: "json",
	// 		type: "GET",
	// 	}).done(callback);
	// }

	getResult(url, data, callback) {
		// console.log(data);
		$.ajax({
		    url,
		    data,
		    dataType: 'json',
		    success: function(data) {
		    	callback(data);
		    }
		});
	}
}
