'use strict';

import $ from 'jquery';
import Pikaday from 'pikaday';

export default class DateFilter {
	constructor() {
		let $dateFilter = $('.date-filter'),
			$dateInput = $('.date-filter__input');

		let pageURL = window.location.pathname;

			this.startPicker = new Pikaday({
				field: document.getElementById('filterStartDate'),
				format: 'DD MMM YYYY',
				onSelect: () => {
					this.endPicker.setMinDate(this.startPicker.getDate());
					this.endPicker.setMaxDate(new Date(this.startPicker.getDate().getTime()+(365*24*60*60*1000)));
				}
			});

			this.endPicker = new Pikaday({
				field: document.getElementById('filterEndDate'),
				format: 'DD MMM YYYY',
				onSelect: () => {
					this.startPicker.setMinDate(new Date(this.endPicker.getDate().getTime()-(365*24*60*60*1000)));
					this.startPicker.setMaxDate(this.endPicker.getDate());
				}
			});

			$dateInput.map((i, ele) => {
				let $this = $(ele);

				$this.on('focusin', function() {
					$this.addClass('focused');
				});

				$this.on('blur', function() {
					if ($this.val() == "") {
						$this.removeClass('focused');
					}
				});
			});



	}
}
