'use strict';

import $ from 'jquery';
import 'fancybox';
import doT from 'dot';
import enquire from 'enquire.js';

export default class ContentBlock {
	constructor() {
		let $contentBlock = $('.content-block'),
			$contentBlockItem = $('.content-block-items', $contentBlock),
			contentBlockApi = $contentBlockItem.data('endpoint');
			// _this = this;

		this.$contentBlockItem = $contentBlockItem;

		// if (!isProd) {
			// let renderResults = (data) => {
			// 	let blockTemplate = $('#dotDetailItem').html();
			// 	let blockContent = '';

			// 	blockContent = doT.template(blockTemplate)(data);

			// 	this.$contentBlockItem.html(blockContent);

			// 	setTimeout(function(){
			// 		// Test history line
			// 		recalculateHistoryHeight();
			// 	}, 1000);
			// }

			// this.getResults(contentBlockApi, renderResults);
		// }


		// enquire.register('screen and (max-width: ' + (1023) + 'px)', {
		// 	match: () => {
		// 		$contentBlock.on("focusin", function() {
		// 			$('.content-block').on('click', '.legend-block', function() {
		// 				// console.log('46');
		// 			});

		// 			$('.explanations-fancybox').on('click', '.explanations-fancybox-close', function() {
		// 				console.log('35');
		// 				$.fancybox.close();
		// 			});
		// 		});
		// 	},
		// 	unmatch: () => {
		// 		$('.tooltiptext').removeClass('clicked');
		// 	}
		// });

		// Calculate history height (test in)
		recalculateHistoryHeight();

		$(window).on('resize', function(){
			recalculateHistoryHeight();
		});

		$(document.body).on('mouseover', '.legend-block' ,function() {
			if ($(this).find('.tooltiptext').length) {
				let $tooltip = $('.tooltiptext', $(this));
				checkInvert($tooltip);
				// console.log('47');
				$tooltip.addClass('clicked');
			}
		});

		$('.legend-block.rationale').map((i, ele) => {
			let $this = $(ele);

			$this.on('click', function() {
				if ($(this).find('.tooltiptext').length && !$(this).find('.clicked').length) {
					let $tooltip = $('.tooltiptext', $(this));
					checkInvert($tooltip);
					// console.log('47');
					$tooltip.addClass('clicked');
				}
			});
		});

		$('.legend-block.note').map((i, ele) => {
			let $this = $(ele);

			$this.on('click', function() {
				if ($(this).find('.tooltiptext').length && !$(this).find('.clicked').length) {
					let $tooltip = $('.tooltiptext', $(this));
					checkInvert($tooltip);
					// console.log('47');
					$tooltip.addClass('clicked');
				}
			});
		});

		$(document.body).on('click', '.legend-block .tooltiptext .icomoon-close' ,function() {
			// console.log('51');
			let $tooltip = $(this).parents().closest('.tooltiptext');
			// console.log($tooltip);
			$tooltip.removeClass('invert');
			$tooltip.removeClass('clicked');
		});

		$('.explanations-link', $contentBlock).each(function(e) {
			$(this).fancybox({
				'autoScale': true,
				'closeBtn': false,
				touch: false,
				smallBtn: false,
				toolbar: false,
				helpers: {
					overlay: {
						locked: false
					}
				},
				beforeShow: function() {
					$('.explanations-fancybox').append('<span class="explanations-fancybox-close"></span>');

					$('.explanations-fancybox-close').on('click', function () {
						// console.log('77');
						$.fancybox.close();
					});
				},
				afterClose: function(){
					$('.explanations-fancybox-close').remove();
				}
			});
		});

		$('.history-link', $contentBlock).each(function(e) {
			let trigger = $(this);

			$(this).fancybox({
				'autoScale': true,
				touch: false,
				'closeBtn': false,
				smallBtn: false,
				toolbar: false,
				helpers: {
					overlay: {
						locked: false
					}
				},
				beforeShow: function() {
					$('.history-fancybox').append('<span class="history-fancybox-close"></span>');

					$('.history-fancybox-close').on('click', function () {
						// console.log('70');
						$.fancybox.close();
					});
				},
				afterClose: function () {
					$('.history-fancybox-close').remove();
				}
			})
		});

		// $contentBlock.on("focusin", function() {
			// console.log('72');

			// $('.explanations-fancybox').on('click', '.explanations-fancybox-close', function() {
			// 	// console.log('77');
			// 	$.fancybox.close();
			// });



			// $('.history-fancybox').on('click', '.history-fancybox-close', function() {
			// 	// console.log('70');
			// 	$.fancybox.close();
			// });
		// });

		function checkInvert($tooltip) {
			let popupHeight = $tooltip.outerHeight();

			let referencePoint = $('.firecode-legend').offset().top,
				selfOffset = $tooltip.parent().offset().top;

			if (popupHeight > (selfOffset - referencePoint)) {
				$tooltip.addClass('invert');
			}
		}

		function recalculateHistoryHeight() {
			if ( $('.amend-start').length === $('.amend-end').length ) {
				$('.amend-start').map((i, ele) => {
					let $this = $(ele);

					if ($this.hasClass('amend-end')) {
						let selfHeight = $this.height();

						$this.find('.legend-block.history').height(selfHeight);
					}
					else {
						// Find endpoint, get its height then add to total height between start to end
						let $end = $this.nextAll('.amend-end').first(),
							endHeight = $end.height(),
							startPoint = $this.offset().top,
							endPoint = $end.offset().top;

						let realHeight = endPoint - startPoint + endHeight;

						$this.find('.legend-block.history').height(realHeight);
					}
				});
			}
		}
	}

	getResults(url, callback) {
		$.ajax({
			url,
			dataType: "json",
			type: "GET"
		}).done(callback);
	}
}
