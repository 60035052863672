'use strict';

import $ from 'jquery';
import FirecodeClauseTable from '../../organisms/firecode-clause-table/firecode-clause-table';

export default class FirecodeClauseFilter {
	constructor() {
		let $clauseFilter = $('.firecode-clause-filter'),
			$clauseSelected = $('.firecode-clause-filter__selected', $clauseFilter),
			$clauseSearch = $('.firecode-clause-filter__search', $clauseFilter),			
			$clauseOptions = $('.options', $clauseFilter);

		$clauseSelected.on('click', (e) => {
			e.stopPropagation();

			if(!$clauseOptions.hasClass('show')){
				this.showOptions($clauseOptions, $('strong', $clauseSelected));
			}
			else {
				this.hideOptions($clauseOptions, $('strong', $clauseSelected));
			}
		});

		let firecodeClause = new FirecodeClauseTable();

		let renderResults = (data) => {
    		let clauseItemTemplate = $('#dotClauseItem').html();
			let tableContent = '';

			tableContent = doT.template(clauseItemTemplate)(data);
			firecodeClause.$fireCodeItemWrapper.html(tableContent);
    	}

		let parseResults = (response) => {
			this.resultsData = response;
			let resultsCopy = [];

			// resultsCopy = cloneResults(this.resultsData);
			// filterResults(response, resultsCopy);
			renderResults(response);
		}

		// first ajax load
		// let firstId = $('li', $clauseOptions).first().find('a').data('chapterid'),
		// 	firstLoadData = { id: firstId };

		let chapterNo = $('li', '.firecode-clause-filter .options').first().data('chapterno');
		let data = { chapterNo };

		this.getResult(firecodeClause.url, data, parseResults);
		// this.getResult(firecodeClause.url, firstLoadData, parseResults);

		// let chapterNo;

		// options clicks handler
		$('li a', $clauseOptions).map((i,ele) => {
			let $this = $(ele);

			$this.on('click', (e) => {
				e.preventDefault();
				e.stopPropagation();

				$('span', $clauseSelected).text($this.text());
				this.hideOptions($clauseOptions, $('strong', $clauseSelected));

				chapterNo = $this.parent().data('chapterno');

				// let id = $this.data('chapterid');
				// firecodeClause.data.id = id;

				// this.getResult(firecodeClause.url, firecodeClause.data, parseResults);
			});
		});

		$clauseSearch.on('click', e => {
			e.preventDefault();

			let data = { chapterNo };

			this.getResult(firecodeClause.url, data, parseResults);
		});
	}

	showOptions($list, $icon){
		$list.addClass('show');
		$icon.addClass('rotate');
	}

	hideOptions($list, $icon){
		$list.removeClass('show');
		$icon.removeClass('rotate');
	}

	// getResult(url, data, callback){
	// 	// console.log('data', data);
	// 	$.ajax({
	// 		url,
	// 		data,
	// 		dataType: "json",
	// 		type: "GET",
	// 	}).done(callback);
	// }

	getResult(url, data, callback) {
		// console.log(data);
		$.ajax({
		    url,
		    data,
		    dataType: 'json',
		    success: function(data) {
		    	callback(data);
		    }
		});
	}
}
