'use strict';

import $ from 'jquery';

export default class FirecodeAmendmentTable {
	constructor() {
		let $firecodeTable = $('.firecode-amendment-table'),
			$firecodeTableTable = $('.firecode-amendment-table__table', $firecodeTable),
			$firecodeHeader = $('.firecode-amendment-table__table--header', $firecodeTableTable),
			$fireCodeItemWrapper = $('.firecode-amendment-table__table--content', $firecodeTableTable),
			$fireCodeItem = $('.firecode-amendment-table__table--item', $firecodeTableTable);

		this.$firecodeTable = $firecodeTableTable;
		this.$firecodeHeader = $firecodeHeader;
		this.$fireCodeItemWrapper = $fireCodeItemWrapper;
		this.$fireCodeItem = $fireCodeItem;

		// data for api
		let url = $firecodeTableTable.data('endpoint');

		let data = {};

		this.url = url;
		this.data = data;
	}
}
