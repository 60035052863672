'use strict';

import $ from 'jquery'

export default class Accordion {
	constructor() {
		let $accordion = $('.accordion');

		$accordion.map((i, ele) => {
			let $this = $(ele),
				$accordionController = $('.accordion__controller', $this),
				$accordionContent = $('.accordion__content', $this);

			// if (i === 0){
			// 	this.open($this);
			// 	emitter.emit('openAccordion');
			// }

			$accordionController.on('click', () => {
				$this.addClass('clicked');

				$('.accordion').each(function() {
					if(!$(this).hasClass('clicked')){
						$(this).find('.accordion__content').slideUp(300);
						$(this).removeClass('open');
					} else {
						$(this).removeClass('clicked');
					}
				});

				if(!$this.hasClass('open')){
					this.open($this);
					emitter.emit('openAccordion');
				}
				else {
					this.close($this);
				}
			});
		});
	}

	close($object) {
		$object.find('.accordion__content').slideUp(300);
		$object.removeClass('open');
	}

	open($object) {
		$object.find('.accordion__content').slideDown(300);
		$object.addClass('open');
	}
}
