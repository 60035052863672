'use strict';

import $ from 'jquery';

export default class FirecodeLegend {
	constructor() {
		let $firecodeLegend = $('.firecode-legend'),
			$legendPrint = $('.print-icomoon', $firecodeLegend);

		$legendPrint.on('click', function(e) {
			window.print();
		});
	}
}
