'use strict';

import $ from 'jquery';

export default class Map {
	constructor() {
		let $mapIframe = $('.map-iframe'),
			long = $mapIframe.data('long'),
			lat = $mapIframe.data('lat'),
			$mapWidth = $mapIframe.width();

		$(window).on('resize', () => {
			let $mapWidth = $mapIframe.width();
			$('iframe', $mapIframe).detach();

			let iFrameSrc = '<iframe src="';
				iFrameSrc += 'https://tools.onemap.sg/minimap/minimap.html?mWidth=' + $mapWidth + '&mHeight=445&amp;latLng=' + lat + ',' + long + '&zoomLevl=17&amp;iwt=&lt;span style=&quot;box-sizing: border-box; font-weight: 700; color: rgb(51, 51, 51); font-family: %26quot;Helvetica Neue%26quot;, Arial, Helvetica, sans-serif; font-size: 12px; background-color: rgb(255, 255, 255);&quot;&gt;&lt;font face=&quot;sans-serif&quot; size=&quot;2&quot; style=&quot;box-sizing: border-box;&quot;&gt;Singapore&lt;/font&gt;&lt;/span&gt;&lt;div style=&quot;box-sizing: border-box; color: rgb(51, 51, 51); font-family: %26quot;Helvetica Neue%26quot;, Arial, Helvetica, sans-serif; font-size: 12px; background-color: rgb(255, 255, 255);&quot;&gt;&lt;span style=&quot;box-sizing: border-box; font-weight: 700;&quot;&gt;&lt;font face=&quot;sans-serif&quot; size=&quot;2&quot; style=&quot;box-sizing: border-box;&quot;&gt;HQ Singapore Civil Defence Force 91 Ubi Ave 4&lt;/font&gt;&lt;/span&gt;&lt;/div&gt;&lt;div style=&quot;box-sizing: border-box; color: rgb(51, 51, 51); font-family: %26quot;Helvetica Neue%26quot;, Arial, Helvetica, sans-serif; font-size: 12px; background-color: rgb(255, 255, 255);&quot;&gt;&lt;span style=&quot;box-sizing: border-box; font-weight: 700;&quot;&gt;&lt;font face=&quot;sans-serif&quot; size=&quot;2&quot; style=&quot;box-sizing: border-box;&quot;&gt;Singapore 408827&lt;/font&gt;&lt;/span&gt;&lt;/div&gt;&amp;popupWidth=200&amp;popupHeight=500&amp;includePopup=true&amp;onloadPopup=false&amp;design=default';
				iFrameSrc += '" height="450px" width="100%" scrolling="no" frameborder="0" allowfullscreen=""></iframe>';

			$mapIframe.append(iFrameSrc);
		});

		let iFrameSrc = '<iframe src="';
			iFrameSrc += 'https://tools.onemap.sg/minimap/minimap.html?mWidth=' + $mapWidth + '&mHeight=445&amp;latLng=' + lat + ',' + long + '&zoomLevl=17&amp;iwt=&lt;span style=&quot;box-sizing: border-box; font-weight: 700; color: rgb(51, 51, 51); font-family: %26quot;Helvetica Neue%26quot;, Arial, Helvetica, sans-serif; font-size: 12px; background-color: rgb(255, 255, 255);&quot;&gt;&lt;font face=&quot;sans-serif&quot; size=&quot;2&quot; style=&quot;box-sizing: border-box;&quot;&gt;Singapore&lt;/font&gt;&lt;/span&gt;&lt;div style=&quot;box-sizing: border-box; color: rgb(51, 51, 51); font-family: %26quot;Helvetica Neue%26quot;, Arial, Helvetica, sans-serif; font-size: 12px; background-color: rgb(255, 255, 255);&quot;&gt;&lt;span style=&quot;box-sizing: border-box; font-weight: 700;&quot;&gt;&lt;font face=&quot;sans-serif&quot; size=&quot;2&quot; style=&quot;box-sizing: border-box;&quot;&gt;HQ Singapore Civil Defence Force 91 Ubi Ave 4&lt;/font&gt;&lt;/span&gt;&lt;/div&gt;&lt;div style=&quot;box-sizing: border-box; color: rgb(51, 51, 51); font-family: %26quot;Helvetica Neue%26quot;, Arial, Helvetica, sans-serif; font-size: 12px; background-color: rgb(255, 255, 255);&quot;&gt;&lt;span style=&quot;box-sizing: border-box; font-weight: 700;&quot;&gt;&lt;font face=&quot;sans-serif&quot; size=&quot;2&quot; style=&quot;box-sizing: border-box;&quot;&gt;Singapore 408827&lt;/font&gt;&lt;/span&gt;&lt;/div&gt;&amp;popupWidth=200&amp;popupHeight=500&amp;includePopup=true&amp;onloadPopup=false&amp;design=default';
			iFrameSrc += '" height="450px" width="100%" scrolling="no" frameborder="0" allowfullscreen=""></iframe>';

		$mapIframe.append(iFrameSrc);
	}
}
