'use strict';

import $ from 'jquery';

export default class SiteSearchDss {
	constructor() {
		let $siteSearch = $('.site-search');
		let $siteSearchOverlay = $('.site-search-overlay');
		let $mobileSiteSearch = $('.m-site-search');
		let $form = $('.site-search__form', $siteSearch);
		let $input = $('.site-search__input', $siteSearch);
		let $submitButton = $('.btn-search', $form);
		let $formMobile = $('.m-site-search__form', $mobileSiteSearch);
		let $inputMobile = $('.m-site-search__input', $mobileSiteSearch);

		this.action = $form.data('action');
		this.actionMobile = $formMobile.data('action');
		this.isOpened = false;

		this.$siteSearch = $siteSearch;
		this.$siteSearchOverlay = $siteSearchOverlay;
		this.$mobileSiteSearch = $mobileSiteSearch;

		this.$input = $input;
		this.$inputMobile = $inputMobile;

		$input.on('keypress', e => {
			if (e.keyCode == 13 || e.which == 13) {
				this.submit();
			}
		});

		$inputMobile.on('keypress', e => {
			if (e.keyCode == 13 || e.which == 13) {
				this.submitMobile();
			}
		});

		$submitButton.on('click', (e) => {
			e.preventDefault();
			this.submit();
		});
	}

	open() {
		this.$siteSearch.slideDown();
		// this.$mobileSiteSearch.slideDown();
		this.isOpened = true;
		this.$siteSearchOverlay.show(0, () => {
			this.$siteSearchOverlay.addClass('shown');
		});
	}

	close() {
		this.$siteSearch.slideUp();
		// this.$mobileSiteSearch.slideUp();
		this.isOpened = false;
		this.$siteSearchOverlay.removeClass('shown');
		setTimeout(() => {
			this.$siteSearchOverlay.hide(0);
		}, 250);
	}

	submit() {
		let name = this.$input.attr('name');
		let value = this.$input.val();

		if (value.trim('').length == 0) {
			if (!this.$siteSearch.find('.error-msg').length) {
				this.$input.after('<p class="error-msg">Please enter keyword to search.</p>')
			}
		} else {
			this.$siteSearch.find('.error-msg').remove();
			window.location.href = `${this.action}?${name}=${encodeURIComponent(value)}`;
		}
	}

	submitMobile() {
		let name = this.$inputMobile.attr('name');
		let value = this.$inputMobile.val();

		if (value.trim('').length == 0) {
			if (!this.$mobileSiteSearch.find('.error-msg').length) {
				this.$inputMobile.after('<p class="error-msg">Please enter keyword to search.</p>')
			}
		} else {
			this.$mobileSiteSearch.find('.error-msg').remove();
			window.location.href = `${this.actionMobile}?${name}=${encodeURIComponent(value)}`;
		}
	}
}
