'use strict';

import $ from 'jquery';

export default class SiteSearch {
	constructor() {
		let $searchBar = $('#searchbar'),
			$mobileSearchBar = $('#mSearchBar');

		this.$searchInput = $('#siteSearch', $searchBar);
		this.$mobileSearchInput = $('#mSiteSearch', $mobileSearchBar);
		this.$searchButton = $('#searchSite', $searchBar);
		this.$mobileSearchButton = $('#mSearchSite', $mobileSearchBar);

		const searchURL = $searchBar.data('url');

		this.$searchButton.on('click', (e) => {
			e.preventDefault();

			let searchQuery = this.makeSearchQuery(this.$searchInput, searchURL);
			window.location.href = searchQuery;

			window.history.pushState({ searchQuery }, '', searchRUrl);
			window.open(searchRUrl, "_self");
		});

		this.$mobileSearchButton.on('click', (e) => {
			e.preventDefault();

			let searchQuery = this.makeSearchQuery(this.$mobileSearchInput, searchURL);
			window.location.href = searchQuery;

			window.history.pushState({ searchQuery }, '', searchRUrl);
			window.open(searchRUrl, "_self");
		});

		this.$searchInput.on('keypress', e => {
			if (e.keyCode == 13 || e.which == 13) {
				e.preventDefault();

				this.$searchButton.trigger('click');
			}
		});

		this.$mobileSearchInput.on('keypress', e => {
			if (e.keyCode == 13 || e.which == 13) {
				e.preventDefault();

				this.$mobileSearchButton.trigger('click');
			}
		});

		if($('.search-results-container').length){
			let get = {};
			let searchParameters = location.search.substr(1).split(/&/);

			for(let i = 0; i < searchParameters.length; i++){
				let tmp = searchParameters[i].split(/=/);
				if (tmp[0] != "") {
					get[decodeURIComponent(tmp[0])] = decodeURIComponent(tmp.slice(1).join("").replace("+", " "));
				}
			}

			let keyword = 'Search Result for "' + get.q + '"';
			$('.searchHeader').text(keyword);
		}
	}

	hideSearchbar() {
		this.$mSearchBar.removeClass('search-open');
	}

	showSearchbar() {
		this.$mSearchBar.addClass('search-open');
		this.$mSearchBar.find('input[type="text"]').focus();
	}

	getQueries() {
		let query_string = {};
		let query = window.location.search.substring(1);
		let vars = query.split("&");
		for (let i = 0; i < vars.length; i++) {
			let pair = vars[i].split("=");

			// If first entry with this name
			if (typeof query_string[pair[0]] === "undefined") {
				query_string[pair[0]] = decodeURIComponent(pair[1]);

			// If second entry with this name
			} else if (typeof query_string[pair[0]] === "string") {
				let arr = [ query_string[pair[0]], decodeURIComponent(pair[1])];
				query_string[pair[0]] = arr;

			// If third or later entry with this name
			} else {
				query_string[pair[0]].push(decodeURIComponent(pair[1]));
			}
		}
		return query_string;
	}

	setInput(value) {
		this.$siteSearch.val(value);
	}

	makeSearchQuery(selector, pURL) {
		let url = window.location.origin,
			searchValue = $(selector).val(),
			pageURL = pURL,
			keywords = searchValue.split(" "),
			keywordLength = keywords.length,
			query = '';

		for (let i = 0; i < keywordLength; i++){
			query += keywords[i];
			query += "+";
		}

		query = query.slice(0, -1);
		let searchQuery = `${url}${pageURL}?q=${query}`;

		return searchQuery;
	}
}
