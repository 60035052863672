'use strict';

import $ from 'jquery';
import 'fancybox';
import 'slick-carousel';

export default class HandbookListingItem {
	constructor() {

		$('.handbook-listing-item__image').slick({
			infinite: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			speed: 600,
			autoplay: false,
			dots: true,
			rows: 0,
			arrows: true,
			prevArrow: '<button type="button" class="slick-prev prev"><i class="icomoon-arrow-prev"></i></button>',
			nextArrow: '<button type="button" class="slick-next next"><i class="icomoon-arrow-next"></i></button>'
		});

		$('.handbook-listing-item__image a[data-fancybox]').fancybox({
			'autoScale': true,
			touch: false,
			helpers: {
				overlay: {
				locked: false
				}
			},
			buttons: [
				"close"
			],
			baseClass: "handbook-fancybox",
			idleTime: false
		});
	}
}
