'use strict';

import $ from 'jquery';

export default class Rte {
	constructor() {
		let $formWrapper = $('.form-container'),
			$stepBtn = $('.formwizard-step a', $formWrapper),
			$wizardContent = $('.setup-content', $formWrapper),
			$nextBtn = $('.btn-next', $wizardContent);

		$wizardContent.hide();

		$stepBtn.on("click", function(e) {
			e.preventDefault();
			var $target = $($(this).attr('href')),
				$item = $(this);

			if (!$item.hasClass('disabled')) {
				$stepBtn.removeClass('btn-success').addClass('btn-default');
				$item.addClass('btn-success');
				$wizardContent.hide();
				$target.show();
				$target.find('input:eq(0)').focus();
			}
		});

		$nextBtn.on("click", function(e) {
			var curStep = $(this).closest(".setup-content"),
				curStepBtn = curStep.attr("id"),
				nextStepWizard = $('div.setup-panel div a[href="#' + curStepBtn + '"]').parent().next().children("a");

			nextStepWizard.removeAttr('disabled').trigger('click');
		});

		$('div.setup-panel div a.btn-success').trigger('click');

		$('.popup-link').on('click', function(e) {
			e.preventDefault();

			let $popup = $('.popup-overlay');

			$popup.css("display", "block");

			$('.popup-overlay__close .close').on('click', function(e) {
				e.preventDefault();

				$popup.css("display", "none");
			});

		});

		filterSelection("all");

		$('.filter-btn').on("click", function() {
			let $this = $(this),
				word = $this.data('filter');
			// console.log(word);
			filterSelection(word);
		});

		function filterSelection(c) {
			var x, i;
			x = $('.filterDiv');
			if (c == "all") c = "";
			for (i = 0; i < x.length; i++) {
				filterRemoveClass(x[i], "show");
				if (x[i].className.indexOf(c) > -1) filterAddClass(x[i], "show");
			}
		}

		function filterAddClass(element, name) {
			var i, arr1, arr2;
			arr1 = element.className.split(" ");
			arr2 = name.split(" ");
			for (i = 0; i < arr2.length; i++) {
				if (arr1.indexOf(arr2[i]) == -1) {element.className += " " + arr2[i];}
			}
		}

		function filterRemoveClass(element, name) {
			var i, arr1, arr2;
			arr1 = element.className.split(" ");
			arr2 = name.split(" ");
			for (i = 0; i < arr2.length; i++) {
				while (arr1.indexOf(arr2[i]) > -1) {
					arr1.splice(arr1.indexOf(arr2[i]), 1);
				}
			}
			element.className = arr1.join(" ");
		}

		var filterWrapper = $('.filter-wrapper');
		var filterBtn = $('.filter-btn', filterWrapper);
		for (var i = 0; i < filterBtn.length; i++) {
		  filterBtn[i].addEventListener("click", function(){
		  	var current = $('.active');
			current[0].className = current[0].className.replace(" active", "");
			this.className += " active";
		  });
		}
	}
}
