'use strict';

import $ from 'jquery';
import 'slick-carousel';
import enquire from 'enquire.js';

export default class FooterLogo {
	constructor() {
		let $footerWrapper = $('.footer-logo'),
      $footerLogoWrap = $('.logo-wrap', $footerWrapper),
			$footerLogo = $('.footer-logo__logos ul', $footerWrapper);

		$footerLogoWrap.slick({
			infinite: false,
			slidesToShow: 5,
			slidesToScroll: 5,
			prevArrow: '<button class="slick-prev carousel-btn carousel-btn__prev" type="button" aria-label="Previous"><strong class="icomoon-arrow-prev"></strong></button>',
			nextArrow: '<button class="slick-next carousel-btn carousel-btn__next" type="button" aria-label="Next"><strong class="icomoon-arrow-next"></strong></button>',
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 4
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3
					}
				},
				{
					breakpoint: 560,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				}
			]
		});
	}
}
